import React, {useEffect} from 'react';
import { Button } from 'semantic-ui-react'

import './style.css';
import AccessChecker from "../AccessChecker";


import ExportIcon from "../../icons/export-icon.png";
import translations from "../../components/LanguageModal/translations";
import {useSelector} from "react-redux";
import {getLanguage} from "../../components/LanguageModal/reducer";

export default function Scan(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    return (
        <div className="scan-view">
            <div className="scan-text">
                {t("pointCamera")}
            </div>
            {/*<div className='scan-icon-container'>*/}
            {/*    <CornerIcon className="scan-photo-icon"/>*/}
            {/*    <PhotoIcon className="scan-photo-icon"/>*/}
            {/*</div>*/}

            <AccessChecker />

            <Button className='scan-btn'>
                <div className="scan-btn-text">{t("export")}</div>
                <img src={ExportIcon} className="scan-export-icon"/>
            </Button>

        </div>
    );
}
