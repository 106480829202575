import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import "./style.css";
import { ReactComponent as CloseIcon } from "../../icons/close-red-icon.svg";
import { v4 as uuidv4 } from 'uuid';

export default function Notifications() {
    const [openNotificationIndex, setOpenNotificationIndex] = useState(null);
    const [circleColors, setCircleColors] = useState({});

    const notifications = [
        {
            title: 'Уже были на АЗИЯЭКСПО? - Поделитесь с нами Вашими впечатлениями!',
            maintext: 'Мы знаем, что Вам было весело и непременно хотим узнать Ваше мнение.',
            id: uuidv4()
        },
        {
            title: 'Форум “Растениеводство” из Ваших закладок начнется через 30 минут в 5 зале.',
            maintext: 'text',
            id: uuidv4()
        }
    ];

    const handleButtonClick = (index) => {
        setCircleColors(prev => ({ ...prev, [index]: 'gray' }));
        setOpenNotificationIndex(index);
    };

    return (
        <>
            <div className="notif-container">
                {notifications.map((notification, index) => (
                    <Button
                        className={'notif-button'}
                        key={notification.id}
                        onClick={() => handleButtonClick(index)}>
                        <span className={'notif-text'}>{notification.title}</span>
                        <div className={circleColors[index] === 'gray' ? 'gray-circle' : 'red-circle'}></div>

                        <Modal
                            className="notif-popup"
                            open={openNotificationIndex === index}
                            onClose={() => {
                                setOpenNotificationIndex(null);
                            }}
                            centered
                        >
                            <Modal.Content className="notif-popup-content">
                                <div className="notif-popup-content-title">
                                    <span className='notif-popup-title-text'>{notification.title}</span>
                                    <button className="notif-popup-button" onClick={() => setOpenNotificationIndex(null)}>
                                        <CloseIcon />
                                    </button>
                                </div>
                                <div className='popup-main-text'>{notification.maintext}</div>
                            </Modal.Content>
                        </Modal>
                    </Button>
                ))}
            </div>
        </>
    );
}
