import React from 'react';
import './style.css';
import { Form, FormField, Input, TextArea, Button } from 'semantic-ui-react'

import { ReactComponent as EditIcon }  from "../../icons/edit-icon.svg";
import { ReactComponent as PlusIcon} from "../../icons/plus-icon.svg";
import LogoCompany from '../../icons/logo-company.png';


export default function Company(props) {
    return (
        <div className="company-view">
            <div className="company-header">
                <div className="company-logo-container">
                    <img src={LogoCompany} alt='Логотип Компании' />
                    <EditIcon className="company-edit-icon"/>
                </div>
                <div className="company-title">CompanyAgriculture<EditIcon className="company-edit-icon company-edit-none"/></div>
            </div>
            <Form className="company-form">
                <FormField className="company-formfield">
                    <label className="company-label">Описание</label>
                    <TextArea type="text" className="company-text-area" placeholder="Пожалуйста, расскажите о своей компании" transparent/>
                </FormField>
                <FormField className="company-formfield">
                    <label className="company-label">Контакты</label>
                    <div className="company-input-container">
                        <Input className="company-input" placeholder='Добавить контактное лицо'></Input>
                        <PlusIcon className="company-plus-icon"/>
                    </div>
                    <div className="company-input-container">
                        <Input className="company-input" placeholder='Добавить контактное лицо'></Input>
                        <PlusIcon className="company-plus-icon"/>
                    </div>
                    <div className="company-input-container">
                        <Input className="company-input" placeholder='Добавить контактное лицо'></Input>
                        <PlusIcon className="company-plus-icon"/>
                    </div>
                    <Button className="company-btn" content="Добавить контактное лицо"></Button>
                </FormField>
                <FormField className="company-formfield">
                    <label className="company-label">Промоматериалы</label>
                    <div className="company-input-container">
                        <Input className="company-input"
                               placeholder='Добавить презентацию, видеоролик, листовку'></Input>
                        <PlusIcon className="company-plus-icon"/>
                    </div>
                    <div className="company-input-container">
                        <Input className="company-input"
                               placeholder='Добавить презентацию, видеоролик, листовку'></Input>
                        <PlusIcon className="company-plus-icon"/>
                    </div>
                    <div className="company-input-container">
                        <Input className="company-input"
                               placeholder='Добавить презентацию, видеоролик, листовку'></Input>
                        <PlusIcon className="company-plus-icon"/>
                    </div>
                </FormField>
                <FormField className="company-formfield">
                    <label className="company-label">Промоматериалы</label>
                </FormField>
            </Form>
        </div>
    )
}
