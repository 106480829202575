import React, {useEffect, useState} from 'react'

import { Select, Input, Form } from 'semantic-ui-react'

import "./style.css"
import { useSelector }  from "react-redux";
import {Link} from "react-router-dom";

import { ReactComponent as AsiaExpoIcon } from '../../icons/asiaexpo2024-icon.svg';
import PhoneCountryMask from "../../components/AddressCard/PhoneCountryMask";
import {isCountriesFetching} from "../../components/AddressCard/reducer";
import {getCountries} from "../../components/AddressCard/reducer";
import {ADDRESS_PHONE, ADDRESS_PHONE_COUNTRY, fetchCounties} from "../../components/AddressCard/actions";
import _ from "lodash";
import {fetchToken} from "../../components/UserLogin/actions";
import {store} from "../../configureStore";
import {openAuth} from "../../components/NavTopBar/actions";
import {isAuthVisible} from "../../components/NavTopBar/reducer";
import UserLogin from "../../components/UserLogin";

export default function Authorization(props) {

    const {dispatch} = props;
    const isFetching = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));

    const authVisible = useSelector((state) => isAuthVisible(state.navtopbar));

    let listPhoneCountries = [];

    listPhoneCountries =
        countries.countries ?
            Object.values(countries.countries).map((element, index) => (
            {
                key:  index,
                text: element.country_ru,
                flag: _.lowerCase(element.iso),
                value: element.iso,
                className: "dropdown-item",
                phone_mask: element.phone_mask,
            }
        )) : [] ;

    useEffect(() => {
        if (true) {
            dispatch(fetchCounties());
        }
    }, []);
    const [selectedCountry, setSelectedCountry] = useState('');

    const handleCountryChange = (event, data) => {
        setSelectedCountry(data.value);
    };

    const countryOptions = [
        { key: 'ru', value: 'ru', flag: 'ru', text: 'Русский' },
        { key: 'en', value: 'en', flag: 'us', text: 'English' }
    ]

    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberContainsUnderscore, setPhoneNumberContainsUnderscore] = useState(true);

    const handleChangeField = (fieldName, value) => {
        const code =   Math.floor(1000 + Math.random() * 9000);
        if (value.includes('_')) {
            setPhoneNumberContainsUnderscore(true);
        } else {
            setPhoneNumberContainsUnderscore(false);
        }
        // генерируем код случайный из 4 цифр
        // ...

        // вызываем функцию отправки этого кода пользователю (ее надо придумать тоже)
        // ...
        // console.log(код)

        // запоминаем в глобальных стейтах телефон и отправленный код
        // ...
        // добавитиь проверку на ввод полного номера телефона
        setPhoneNumber(value);
    }

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailDirty, setEmailDirty] = useState(false);
    const [passwordDirty, setPasswordDirty] = useState(false);
    const [emailError, setEmailError] = useState("Поле ввода не может быть пустым");
    const [passwordError, setPasswordError] = useState("Поле ввода не может быть пустым");
    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        if (emailError || passwordError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }, [emailError, passwordError]);


    const passwordHandler = (e) => {
        setPassword(e.target.value);
        if(e.target.value.length < 6 || e.target.value.length > 12) {
            setPasswordError("Пароль должен быть от 6 до 12 символов")
            if(!e.target.value) {
                setPasswordError("Поле ввода не может быть пустым");
            }
        } else {
            setPasswordError("");
        }
        // вызываем для авторизации
        //
        // dispatch(fetchToken(state.email, state.password))

        //
        //  const isFetching = useSelector((state) => getUserFetching(state.userLogin));
        //  - нарисовать крутилку пока идет запрос на вход
        //
        //  isLoggedIn: useSelector((state) => getUserLoggedIn(state.userLogin))
        // определяет вошел ли юзер или нет
    }

    const emailHandler = (e) => {
        setEmail(e.target.value);
        if(!e.target.value) {
            setEmailError("Поле ввода не может быть пустым");
        }
        else {
            setEmailError("");
        }
    }


    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'email':
                setEmailDirty(true);
                break;
            case 'password':
                setPasswordDirty(true);
                break;
        }
    }

    console.log('App - глобальные стейты REDUX', store.getState());

    return (
        <div className='main-block3'>
            <Select options={countryOptions} placeholder="Выберите язык" style={{
                borderRadius: '12px',
                background: '#D9D9D9',
                textColor: '#000000',
                fontFamily: 'Muller'
            }} value={selectedCountry}
                    onChange={handleCountryChange}/>

            <AsiaExpoIcon/>
            <span className='text-for-label'> Для входа в приложение введите ваш номер<br/> телефона в международном формате</span>

            <PhoneCountryMask
                handleChangeField={handleChangeField}
                extraClass="shipping-field shipping-phone"
                countries={listPhoneCountries}
                loading={isFetching}
                country="RU"
                phone={phoneNumber}
                nameCountry={ADDRESS_PHONE_COUNTRY}
                namePhone={ADDRESS_PHONE}
                fluid={false}
            />


            <button
                onClick={() => dispatch(openAuth())}
            >
                войти по логину и паролю
            </button>
            <UserLogin showModal={authVisible} {...props}/>

            <div className='container-buttons'>
                <Link className="button1" to=
                    {{
                        pathname: '/confirmation-code',
                    }}
                >
                    <button disabled={phoneNumberContainsUnderscore}
                            className={!phoneNumberContainsUnderscore ? 'text-for-link' : 'text-for-link-disabled'}>Отправить
                        код подтверждения
                    </button>
                </Link>
            </div>
            <br/>
            <div className='container-inputsss'>
                {(emailDirty && emailError) && <div style={{color: 'red', fontFamily: 'Muller'}}>{emailError}</div>}
                <Input onChange={e => emailHandler(e)} value={email} onBlur={e => blurHandler(e)} name='email'
                       type='text' id='input-numbers' placeholder='Логин или почта' style=
                           {{
                               width: '100%',
                               maxWidth: '65vw',
                               fontSize: '14px'
                           }}/>
                {(passwordDirty && passwordError) &&
                    <div style={{color: 'red', fontFamily: 'Muller'}}>{passwordError}</div>}
                <Input onChange={e => passwordHandler(e)} value={password} onBlur={e => blurHandler(e)} name='password'
                       id='input-numbers' placeholder='Пароль' style=
                           {{
                               width: '100%',
                               maxWidth: '65vw',
                               fontSize: '14px'
                           }}/>
            </div>
            <div className='container-buttons'>
                <Link className="button1" to='/' type='submit'>
                    <button style={{fontFamily: 'Muller-Bold'}} disabled={!formValid}
                            className={formValid ? 'text-for-link' : 'text-for-link-disabled'}>Войти
                    </button>
                </Link>
                <Link className="button1" to='/registration'>
                    <div className='text-for-link'>Регистрация</div>
                </Link>
            </div>
        </div>
    )
}

