import { SET_FORM_CHANGED, RESET_FORM_CHANGED, SET_POPUP_OPEN, SET_POPUP_CLOSE } from "./actions";


const initialState = {
    isFormChanged: false,
    isPopupOpen: false,
};

const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FORM_CHANGED:
            return {
                ...state,
                isFormChanged: true,
            }
        case RESET_FORM_CHANGED:
            return {
                ...state,
                isFormChanged: false,
            }
        case SET_POPUP_OPEN:
            return {
                ...state,
                isPopupOpen: true,
                actionRoute: action.route
            }
        case SET_POPUP_CLOSE:
            return {
                ...state,
                isPopupOpen: false,
            }
        default:
            return state;
    }
};

export default formReducer;
