import React, { useState, useEffect } from 'react'
import {Button, Select} from 'semantic-ui-react'
import "./style.css"
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as AsiaExpoIcon } from '../../icons/asiaexpo2024-icon.svg';

import {useSelector} from "react-redux";
import {getAuthMode, getUserLoggedIn} from "../../components/UserLogin/reducer";


export default function ConfirmationCode(props) {
    const {dispatch} = props
    const countryOptions = [
        { key: 'ru', value: 'ru', flag: 'ru', text: 'Русский' },
        { key: 'en', value: 'en', flag: 'us', text: 'English' }
    ]

    const [seconds, setSeconds] = useState(30);
    const [textColor, setTextColor] = useState("#A7AFB7");
    const [inputs, setInputs] = useState(['', '', '', '']);
    const navigate = useNavigate();

    const location = useLocation();
    const phoneNumber = location.state ? location.state.phoneNumber : "";

    const authMode = useSelector((state) => getAuthMode(state.userLogin));


    useEffect(() => {
        if (seconds > 0) {
            const intervalId = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [seconds]);

    const handleClick = () => {
        setSeconds(30);
        setTextColor('#A7AFB7');
        const code =   Math.floor(1000 + Math.random() * 9000);

    }

    const handleInput = (index, value) => {
        const newInputs = [...inputs];
        newInputs[index] = value;
        setInputs(newInputs);

        const enteredCode = newInputs.join("");
        const correctCode = authMode.authCode
        if (enteredCode.length === 4) {
            if (Number(enteredCode) === correctCode) {
                navigate('/');
            } else {


            }
        }
    }




    return (
        <div className='main-block5'>
            <AsiaExpoIcon />
            <div className='container-numbers'>
                <span className='text-for-label'>Введите четырехзначный код <br /> отправленный на номер</span>
                <span className='number-phone' id='changePhone'>{authMode.authPhone}</span>
            </div>
            <button id='btnCode' onClick={handleClick} disabled={seconds > 0}>
                <span className='text-again' style={{ color: seconds === 0 ? 'black' : textColor }}>{seconds === 0 ? 'Отправить повторно' : `Отправить повторно (${seconds} сек)`}</span>
            </button>
            <div className='container-inputs'>
                {
                    inputs.map((value, index) => (
                        <input
                            key={index}
                            id='input-number13'
                            maxLength='1'
                            value={value}
                            onChange={(e) => handleInput(index, e.target.value)}
                        />
                    ))
                }
            </div>
        </div>
    )
}
