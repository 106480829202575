import { combineReducers } from 'redux';
import _ from 'lodash';
import {REQUEST_CATEGORIES, RECEIVE_CATEGORIES, CATEGORIES_PAGE_COUNT} from './actions';
import config from "../../config/config";
import {mergeObjectArrays} from "../../configureStore";

const items = (state = [], action) => {
  switch (action.type) {
    case REQUEST_CATEGORIES:
      return state;
    case RECEIVE_CATEGORIES:
      if (action.page === 1)
        return action.categories;
      else
        return mergeObjectArrays(action.categories, state);
    default:
      return state;
  }
};

const parent = (state = 0, action) => {
  switch (action.type) {
    case REQUEST_CATEGORIES:
      return action.parent;
    default:
      return state;
  }
};

const hasMore = (state = false, action) => {
  switch (action.type) {
    case REQUEST_CATEGORIES:
      return true;
    case RECEIVE_CATEGORIES:
      return action.categories.length >= CATEGORIES_PAGE_COUNT;
    default:
      return state;
  }
};

const isFetching = (state = {fetching: false, page: 1}, action) => {
  switch (action.type) {
    case REQUEST_CATEGORIES:
      return {fetching: true, page: action.page};
    case RECEIVE_CATEGORIES:
      return {...state, fetching: false};
    default:
      return state;
  }
};

export const getCategories = state => state.items;
export const getCategoriesFetching = state => state.isFetching.fetching && state.isFetching.page === 1;
export const getCategoriesParent = state => state.parent;
export const getCategoriesHasMore = state => state.hasMore;

export default combineReducers({
  items,
  parent,
  isFetching,
  hasMore,
});
