import { combineReducers } from 'redux';
import * as types from './actions';

const search = (state = false, action = 0) => {
  switch (action.type) {
    case types.SEARCH_OPEN:
      return true;
    case types.SEARCH_CLOSE:
      return false;
    default:
      return state;
  }
};

const auth = (state = false, action = 0) => {
  switch (action.type) {
    case types.AUTH_OPEN:
      return true;
    case types.AUTH_CLOSE:
      return false;
    default:
      return state;
  }
};




export const isSearchVisible = (state) => state.search;
export const isAuthVisible = (state) => state.auth;
export default combineReducers({
  search,
  auth,
});






