import { combineReducers } from 'redux';
import {
  REQUEST_USER_DATA,
  RECEIVE_USER_DATA,
  REQUEST_TOKEN,
  RECEIVE_TOKEN,
  DROP_TOKEN,
  RECEIVE_MOBILE_OTP,
  REQUEST_MOBILE_OTP,
  SET_AUTH_SCREEN, RECEIVE_RESET_PWD_OTP, REQUEST_RESET_PWD_OTP
} from './actions';

const authMode = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_AUTH_SCREEN:
      return {...state, mode: action.screenCode};
    case RECEIVE_MOBILE_OTP:
      return {mode: action.screenCode, data: action.data};
    case RECEIVE_RESET_PWD_OTP:
      return {mode: action.screenCode, data: action.data};
    default:
      return state;
  }
};

const isAuthFetching = (state = false, action = {}) => {
  switch (action.type) {
    case REQUEST_MOBILE_OTP:
    case REQUEST_RESET_PWD_OTP:
    case REQUEST_USER_DATA:
    case REQUEST_TOKEN:
      return true;
    case RECEIVE_MOBILE_OTP:
    case RECEIVE_USER_DATA:
    case RECEIVE_TOKEN:
    case RECEIVE_RESET_PWD_OTP:
    case DROP_TOKEN:
      return false;
    default:
      return state;
  }
};

const userData = (state = {}, action = {}) => {
  switch (action.type) {
    case REQUEST_USER_DATA:
      return state;
    case RECEIVE_USER_DATA:
      if (action.data.code)
        return state;
      else
        return action.data;
    default:
      return state;
  }
};

const isUserDataFetching = (state = false, action = {}) => {
  switch (action.type) {
    case REQUEST_USER_DATA:
      return action.query ?? true;
    case RECEIVE_USER_DATA:
      return false;
    default:
      return state;
  }
};

const userToken = (state = [], action = {}) => {
  switch (action.type) {
    case REQUEST_TOKEN:
      return state;
    case DROP_TOKEN:
    case RECEIVE_TOKEN:
      return action.data;
    default:
      return state;
  }
};

const isUserTokenFetching = (state = false, action = {}) => {
  switch (action.type) {
    case REQUEST_TOKEN:
      return true;
    case RECEIVE_TOKEN:
    case DROP_TOKEN:
      return false;
    default:
      return state;
  }
};

export const getAuthFetching = (state) => state.isAuthFetching;
export const getAuthMode = (state) => state.authMode;
export const getUserData = (state) => state.userData;
export const getAuthToken = (state) => state.userToken.token;
export const getUserFetching = (state) => state.isUserTokenFetching || Boolean(state.isUserDataFetching);
export const getUserUpdatingQuery = (state) => typeof state.isUserDataFetching === 'string' ? state.isUserDataFetching : '';
export const getUserLoggedIn = (state) => state.userToken.loggedIn;
export const getUserLoginError = (state) => (state.userToken.message ? state.userToken.message : '');
export const getUserDisplayName = (state) => state.userToken.userDisplayName;

const userLogin = combineReducers({
  userData,
  isUserDataFetching,
  userToken,
  isUserTokenFetching,
  authMode,
  isAuthFetching,
});

export default userLogin;
