import React, {useEffect} from 'react';
import ReduxToastr from 'react-redux-toastr';
import {useDispatch, useSelector} from 'react-redux';
import { Sidebar, Sticky } from 'semantic-ui-react';

import {MixpanelProvider, useMixpanel} from 'react-mixpanel-browser';

import { isMenuVisible } from './components/NavBottomBar/reducer';
import { closeMenu } from './components/NavBottomBar/actions';
import NavTopBar from './components/NavTopBar';
import NavBottomBar from './components/NavBottomBar';
import SideMenu from './views/SideMenu';

import './App.css';
import {MIXPANEL_API_KEY} from "./config/secrets";
import {isSearchVisible} from "./components/NavTopBar/reducer";
import {closeSearch} from "./components/NavTopBar/actions";

import {store} from "./configureStore";
import {useNavigate} from "react-router-dom";
import {getAuthToken, getUserData, getUserLoggedIn} from "./components/UserLogin/reducer";
import {getLocationData} from "./components/GeoLocation/reducer";
import {isArray} from "lodash";
import {getForums} from "./views/BusinessProgram/reducer";
const MIXPANEL_TOKEN = MIXPANEL_API_KEY;

const MIXPANEL_CONFIG = {
    track_pageview: true, // Set to `false` by default
}

export const Base64= {_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}

function App (props) {

  const router = useSelector((state) => state.router);
  const defaultProps = {
    navigate: useNavigate(),
    dispatch: useDispatch(),
    searchVisible: useSelector((state) => isSearchVisible(state.navtopbar)),
    menuVisible: useSelector((state) => isMenuVisible(state.navbottombar)),
    userData: useSelector((state) => getUserData(state.userLogin)),
    userLocation: useSelector((state) => getLocationData(state.userLocation)),
    isLoggedIn: useSelector((state) => getUserLoggedIn(state.userLogin)),
    token: useSelector((state) => getAuthToken(state.userLogin)),

    mixpanel: useMixpanel(),
    routeAction: router.action,
    location: router.location,
    previousLocations: router.previousLocations,
    isPageReloaded: router.action === 'POP' && isArray(router.previousLocations) && _.size(router.previousLocations) === 1,
  }

  const {children} = props;
  const {routeAction, location, previousLocations, menuVisible, searchVisible, dispatch} = defaultProps;

  if ( process.env.REACT_APP_ENV !== 'production') {
    console.log('App - глобальные стейты REDUX', store.getState());
    console.log('App - router-redux action, location, previous', routeAction, location, previousLocations);
  }
  function hideBars () {

    if (menuVisible)
      dispatch(closeMenu());

    if (searchVisible)
      dispatch(closeSearch());
  }

  return (
      <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}>
      <div>
        <ReduxToastr
          timeOut={4000}
          newestOnTop
          preventDuplicates
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
        />
        <NavTopBar {...defaultProps}/>
        <Sidebar.Pushable style={{ transform: 'none' }}>
          <Sticky>
            <SideMenu {...defaultProps}/>
          </Sticky>
          <Sidebar.Pusher
            dimmed={menuVisible || searchVisible}
            onClick={hideBars}
            style={{ minHeight: '100vh' }}
          >
            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        <NavBottomBar {...defaultProps}/>
      </div>
      </MixpanelProvider>
    );
}

export default App;

