import 'whatwg-fetch';
import config, {AUTH_INVALID_MESSAGE, AUTH_INVALID_TOKEN, SERVICE_LOGICAL_ACCESS_DENIED} from '../../config/config';
import {dropToken} from "../../components/UserLogin/actions";
import {toastr} from "react-redux-toastr";

export const SEARCH_PAGE_COUNT = 20;
export const REQUEST_SEARCH = 'REQUEST_SEARCH';
export const RECEIVE_SEARCH = 'RECEIVE_SEARCH';
export const RECEIVE_SEARCH_UPDATE = 'RECEIVE_SEARCH_UPDATE';
export const SET_SEARCH_CONTEXT = 'SET_SEARCH_CONTEXT';

export const SEARCH_CONTEXT_EXPONENTS = 'exponents';
export const SEARCH_CONTEXT_UNSET = '/';
export const SEARCH_CONTEXT_DEFAULT = config.DEFAULT_SEARCH_IN;

export const SEARCH_CONTEXTS = [
    // { key: SEARCH_CONTEXT_TASKS, text: 'Задачи', value: SEARCH_CONTEXT_TASKS, title: 'в задачах' },
     { key: SEARCH_CONTEXT_EXPONENTS, text: 'Экспоненты', value: SEARCH_CONTEXT_EXPONENTS, title: 'в экспонентах' },
]

export const setSearchContext = (value) => {
    return {
    type: SET_SEARCH_CONTEXT,
    value,
}
};
export const receiveSearch = (context, page, items) => ({
    type: RECEIVE_SEARCH,
    items,
    page,
    context,
});

export const receiveSearchUpdate = (context, page, items) => ({
    type: RECEIVE_SEARCH_UPDATE,
    items,
    page,
    context,
});

export const requestSearch = (context, page) => ({
    type: REQUEST_SEARCH,
    page,
    context,
});

const process_api_error = (json, dispatch) => {
  if (json.code === AUTH_INVALID_TOKEN) {
        dispatch(dropToken());
        toastr.error(AUTH_INVALID_MESSAGE);
  } else
      if (json.code === SERVICE_LOGICAL_ACCESS_DENIED)
        toastr.error(json.message);
      else
        toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const fetchSearch = (token, params = {}) => (dispatch) => {
    console.log('jsom search send', params);
  dispatch(requestSearch('exponents', params.page));

  let base_url = config.API_EXPONENTS_URL;

  const url = base_url
        + '?' + Object.keys(params).map((k) => k + '=' + encodeURIComponent(params[k])).join('&');

  return fetch(url)
    .then((response) => response.json())
      .then((json) => {
          console.log('jsom search responce', json)
        if (json.code ?? 200 === 200) {
          dispatch(receiveSearch('exponents', params.page, json.data ?? json));
        } else {
          process_api_error(json, dispatch);
        }
      })
      .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)));
};
