import React, {useEffect, useRef, useState} from 'react';
import {
    Sidebar, Menu, Icon, Header, Dropdown, Segment, Divider,
} from 'semantic-ui-react';

import './styles.css';
import {getElementDimensionsById, useWindowDimensions} from "../../components/WindowDimensions";
import config from "../../config/config";
import {closeSearch} from "../../components/NavTopBar/actions";
import {dropToken} from "../../components/UserLogin/actions";
import {closeMenu} from "../../components/NavBottomBar/actions";

export default function SideMenu(props) {

    const {dispatch, mixpanel, menuVisible, searchVisible, isLoggedIn, navigate} = props;
    const {height, width} = useWindowDimensions();
    const [modalPDF, setModalPDF] = useState(false)
    const [secondMenu, setSecondMenu] = useState(false)
    const [firstMenu, setFirstMenu] = useState(false)
    const menuItemRef = useRef();



    const [sidebarStyle, setSidebarStyle] = useState({
        right: '0!important',
    });

    useEffect(() => {
        applyStyle();
        window.addEventListener('resize', applyStyle);
        return () => window.removeEventListener('resize', applyStyle);
    }, []);

    useEffect(() => {
        applyStyle();
        if (menuVisible !== true)
        setTimeout(function(){ setFirstMenu(menuVisible)},500);
        else
            setFirstMenu(menuVisible)
        if (searchVisible && menuVisible) {
            dispatch(closeSearch());
        }
        if (mixpanel) mixpanel.track('Side Menu Open', {menuVisible, isLoggedIn});
        if (menuVisible === false) setSecondMenu(false)
    }, [menuVisible]);

    useEffect(() => {
        if (menuItemRef.current && menuItemRef.current.offsetTop) {
            const topOffset = menuItemRef.current.offsetTop;
            const sidebar = document.getElementById('menu-second-side-bar');
            sidebar.style.top = `${topOffset}px`;
        }
    }, [menuItemRef]);

    function applyStyle() {
        const rootDimensions = getElementDimensionsById('root');
        const right = `${((width - rootDimensions.width) / 2)}px!important`;
        setSidebarStyle({...sidebarStyle, right});
    }

    function toggleMenu() {
        setSecondMenu(!secondMenu);
    }

    return (<div className="side-bar-wrapper">
            <div id="portal-root"></div>
            <Sidebar
                as={Menu}
                animation="scale down"
                width="thin"
                visible={firstMenu}
                icon="labeled"
                inverted
                color="blue"
                direction="right"
                vertical
                className="menu-side-bar"
                id="menu-side-bar"
                style={{...sidebarStyle}}
            >
                <Header as="h3" inverted>
                    Меню
                </Header>
                <Menu.Item className="shop-icon-item" name="help circle"
                           onClick={closeMenu}>
                    <Icon name="help circle" size="tiny"/>
                    <div className="shop-text-item"> Версия {config.APP_VERSION} </div>
                    {process.env.REACT_APP_ENV === 'production' ? '' :
                        <div className="shop-text-item"> {process.env.REACT_APP_ENV}</div>}
                </Menu.Item>
                <Menu.Item className="shop-icon-item" name="locations"
                           onClick={closeMenu}>
                    <Icon name="move" size="tiny"/>
                    <div className="shop-text-item"> {width} x {height} px</div>
                </Menu.Item>
                <Menu.Item onClick={toggleMenu} id="support" ref={menuItemRef}>
                    <Icon name="headphones" size="tiny"/>
                    <div className="shop-text-item"> Поддержка</div>
                </Menu.Item>
                {isLoggedIn ? <Menu.Item className="shop-icon-item" name="logout"
                                         onClick={() => {
                                             dispatch(closeMenu());
                                             dispatch(dropToken());
                                         }}>
                    <Icon name="log out" size="tiny"/>
                    <text className="shop-text-item"> Выйти из учетной записи</text>
                </Menu.Item> : ''}
            </Sidebar>
            <Sidebar
                as={Menu}
                icon="labeled"
                inverted
                color="blue"
                vertical
                visible={secondMenu}
                animation="push"
                width="thin"
                direction="right"
                className="menu-second-side-bar"
                id="menu-second-side-bar"
            >
                <Header as="h4" inverted className="header-second-menu">
                    Поддержка
                </Header>
                <Divider className="header-divider-menu"/>
                <Menu.Item className="item-second-menu" name="help circle"
                           //onClick={() => setModalPDF(true)}
                           onClick={() => navigate("/instructions")}
                >
                    <div className="field-second-menu">
                        <span className="icon-second-menu">
                            <Icon name="book" size="large"/>
                        </span>
                        <span>Инструкции</span>
                    </div>
                </Menu.Item>
                <Menu.Item className="item-second-menu" name="locations"
                           onClick={() => navigate("/faq")}>
                    <div className="field-second-menu">
                        <span className="icon-second-menu">
                            <Icon name="question" size="large"/>
                        </span>
                        <span>Часто задаваемые вопросы</span>
                    </div>
                </Menu.Item>
                <Menu.Item className="item-second-menu" name="locations"
                           href={'mailto:support-service@e-feed.ru'}>
                    <div className="field-second-menu">
                        <span className="icon-second-menu">
                            <Icon name="mail" size="large"/>
                        </span>
                        <span>Эл. почта</span>
                    </div>
                </Menu.Item>
                <Menu.Item className="item-second-menu" href={'tel:88006008620'}>
                    <div className="field-second-menu">
                        <span className="icon-second-menu">
                            <Icon name="call" size="large"/>
                        </span>
                        <span>Позвонить на горячую линию бесплатно</span>
                    </div>
                </Menu.Item>
            </Sidebar>

        </div>
    );
}