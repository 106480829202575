import React, {useEffect, useRef, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';

import {
    Card, Divider, Header, Icon, Loader, Button, Image, Container, Dimmer,
} from 'semantic-ui-react';
import {isSearchVisible} from "../../components/NavTopBar/reducer";
import {getAuthToken, getUserData} from "../../components/UserLogin/reducer";
import {getLocationData} from "../../components/GeoLocation/reducer";
import {closeSearch} from "../../components/NavTopBar/actions";
import Html5QrcodePlugin from "./Html5QrcodePlugin";
import GeoLocation from "../../components/GeoLocation/GeoLocation";
import {getTicketData, isTicketFetching} from "./reducer";
import {fetchTicketData, receiveTicketData} from "./actions";
import {Link} from "react-router-dom";

import './styles.css';
import {toastr} from "react-redux-toastr";
import {isMobile} from 'react-device-detect';
import {isNull} from "lodash";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";

export default function AccessChecker(props) {

    const userLocation  = useSelector((state) => getLocationData(state.userLocation));
    const userData  = useSelector((state) => getUserData(state.userLogin));
    const dispatch = useDispatch();
    const mixpanel = useMixpanel();

    const [stateMode, setStateMode] = useState({
        mode: 'scanning',
        style: {display: 'block'},
    });

    const scannerRef = useRef(null);


    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const [stateQrCode, setStateQrCode] = useState("");
    const prevQrCode = usePrevious(stateQrCode);


    function unicodeToChar(text) {
        return text.replace(/\\u[\dA-F]{4}/gi,
            function (match) {
                return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
            });
    }

    function onNewScanResult (decodedText, decodedResult)  { setStateQrCode(decodedText); }

    function onScanFailure(error) {
        mixpanel.track('Scanner New Error', { ...stateMode, ...userLocation, ...userData, ...currentExpoLocation, 'qr-scan-error': error });
        console.warn(`Code scan error = ${error}`);
    }

    function onButtonApproveContinue() {
        setStateMode({style: {display: 'block'}, mode: 'scanning'});
        dispatch(receiveTicketData([]));
    }

    function closeModalSetup() {
        setStateMode({style: {display: 'block'}, mode: 'scanning'});
    }
    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    return (
        <div>
               <Card centered color="orange" className="scanner-card">
                    <Header as='h3' color="blue" className="scanner-reader">
                        <Icon name='photo' />
                        <Header.Content>
                            {t("scanBadge")}
                            <Header.Subheader>{t("pointQR")}</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Html5QrcodePlugin
                        forwardRef = {scannerRef}
                        aspectRatio={4/3}
                        fps={25}
                        //qrbox={stateQrBox}
                        disableFlip={false}
                        qrCodeSuccessCallback={onNewScanResult}
                        verbose={false}
                    />
                </Card>
            {stateMode.mode === 'setup' ?
                <ExpoLocation isOpen={true}  closeFunction={closeModalSetup}/> : ''
            }
        </div>
    );
}
