import React, {useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    getElementDimensionsById,
    getWindowDimensions,
    setWindowScrollPosition,
    useScrollPosition,
} from "../WindowDimensions";
import {Container, Dimmer, Header, Icon, Loader} from "semantic-ui-react";
import "./style.css"

export default function InfiniteView(props) {

    const {dataLength, nextFetch, hasMore, dataList, mustAuth, isAuth, isLoading, loaderText, zeroText, headerTitle, hideEndMessage} =
        props;

    const noLoginText = 'Для получения доступа к списку, пожалуйста, авторизуйтесь';

    const [viewPort, setViewPort] = useState(
        {curX: 0, curY: 0, prevX: 0, prevY: 0, moveUp: false, moveDown: false, isButtonVisible: false});

    const [buttonGoTopStyle, setButtonGoTopStyle] = useState({
        transition: 'all 200ms ease-out',
        position: 'fixed',
        zIndex: '199',
        visibility: 'hidden',
        transform: 'none',
    });

    useEffect(() => {
        setButtonNewPosition();
        window.addEventListener('resize', setButtonNewPosition);
        return () => window.removeEventListener('resize', setButtonNewPosition);
    }, []);

    useScrollPosition(
        ({ prevPos, currPos }) => {

            const windowDimensions = getWindowDimensions();

            const isVisible = currPos.y < prevPos.y
                && Math.abs(currPos.y) > windowDimensions.height
                && Math.abs(prevPos.y - currPos.y) > 1;

            setViewPort({
                curX: currPos.x,
                curY: currPos.y,
                prevX: prevPos.x,
                prevY: prevPos.y,
                moveUp: currPos.y < prevPos.y,
                moveDown: currPos.y > prevPos.y,
            });

            const shouldBeStyle = {
                ...buttonGoTopStyle,
                visibility: isVisible ? 'visible' : 'hidden',
                transition: `all 200ms ${isVisible ? 'ease-in' : 'ease-out'}`,
                transform: isVisible ? 'none' : 'translate(0, 100%)',
            }

            if (JSON.stringify(shouldBeStyle) === JSON.stringify(buttonGoTopStyle))
                return;

            setButtonGoTopStyle(shouldBeStyle);
        },
        [buttonGoTopStyle], null, true,300);

    function setButtonNewPosition () {
        const windowDimensions = getWindowDimensions();
        const rootDimensions = getElementDimensionsById('root');
        const navBottomBarDimensions = getElementDimensionsById('nav-bottom-bar');
        const shouldBeStyle = {
            ...buttonGoTopStyle,
            right: `${( (windowDimensions.width - rootDimensions.width) / 2 )}px`,
            bottom: `${( navBottomBarDimensions.height + 10 )}px`,
        }

        if (JSON.stringify(shouldBeStyle) === JSON.stringify(buttonGoTopStyle))
            return;

        setButtonGoTopStyle(shouldBeStyle);
    }

    function loadMore() {
        if (hasMore) {
            nextFetch();
            setWindowScrollPosition(viewPort.curX, viewPort.curY - 1);
        }
    }

    return (
        <>
            <Dimmer className="page-loader" active={isLoading} inverted>
                <Loader active>{loaderText}</Loader>
            </Dimmer>
            {_.isUndefined(headerTitle) ? '' :
                <Header className="infinite-view-header" textAlign="center">
                    {headerTitle}
                </Header>}
            { dataLength === 0 && !isLoading || mustAuth && !isAuth
            ?
                <Container>
                    <p>
                        {mustAuth && !isAuth ? noLoginText : zeroText}
                    </p>
                </Container>
            :
                <InfiniteScroll
                        dataLength={dataLength}
                        next={loadMore}
                        hasMore={hasMore}
                        loader={false}
                        endMessage={hideEndMessage ? '' : (
                            <p style={{ textAlign: 'center' }}>
                                <b>Конец списка</b>
                            </p>
                        ) }>
                        {dataList}
                        <Icon
                            name="arrow alternate circle up"
                            color="red"
                            size="huge"
                            style={{...buttonGoTopStyle}}
                            onClick={setWindowScrollPosition}
                            className="infinite-scroll-top-button"
                        />
                </InfiniteScroll>
            }
        </>
    );
}