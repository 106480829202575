import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import "./style.css";
import {ReactComponent as Icon} from "../../icons/download-icon.svg";
import html2pdf from "html2pdf.js";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import SadIcon from '../../icons/sad-icon.png';
import {toastr} from "react-redux-toastr";
import {fetchOrders, ORDERS_PAGE_COUNT} from '../Orders/actions';

const Ticket = (props) => {
    const {isLoggedIn} = props;

    const dispatch = useDispatch();
    const userData = useSelector(state => state.userLogin.userData);
    const orders = isLoggedIn ? useSelector(state => state.userLogin.userData.orders.active) : useSelector(state => state.userLogin.userData.orders);
    const [userName, setUserName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userCompany, setUserCompany] = useState("");
    const language = useSelector(getLanguage);
    const languageString = language[0] + language[1];

    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const downloadPdf = () => {
        try {
            const element = document.getElementById("ticket-page-container");
            const options = {
                margin: 1,
                filename: 'asiaexpoTicket.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            };

            html2pdf()
                .set(options)
                .from(element)
                .save()
                .catch(err => console.error('Error generating PDF:', err));
        } catch (error) {
            console.error('Error in downloadPdf function:', error);
        }
    };

    const handleBuyButton = () => {
        toastr.success("в разработке");
    };

    useEffect(() => {
        if (isLoggedIn && userData) {
            setUserName(userData.billing.first_name || "");
            setUserLastName(userData.billing.last_name || "");
            setUserCompany(userData.billing.company || "");
            if (!orders) {
                dispatch(fetchOrders(userData.id, {
                    order: 'desc',
                    orderby: 'id',
                    per_page: ORDERS_PAGE_COUNT,
                    lang: languageString,
                }));
            }
        }
        window.scrollTo(0, 0);
    }, [userData]);

    const hasCompletedTicket = isLoggedIn ? orders?.some(order => order.order_status === 'processing') : false;
    const hasPendingOrder = isLoggedIn ? orders?.some(order => ['on-hold', 'pending'].includes(order.order_status)) : false;

    if (!isLoggedIn || !hasCompletedTicket) {
        return (<div className="no-ticket-page">
                <img src={SadIcon} alt="" />
                <div className="no-ticket-message">
                    {!isLoggedIn ? (
                        <span>У вас нет билета.<br /><br />
                        Пожалуйста, войдите в систему и оплатите билет ниже с помощью кнопки "Купить билет".</span>
                    ) : hasPendingOrder ? (
                        <span>Ваш билет на удержании или ожидает оплаты.<br /><br />
                        Пожалуйста, завершите оплату ниже.</span>
                    ) : (
                        <span>У вас нет билета.<br /><br />
                        Пожалуйста, оплатите билет ниже с помощью кнопки "Купить билет".</span>
                    )}
                </div>
                <div className="button-container" style={{marginTop: '20px'}}>
                    <button className="buy-ticket-button" onClick={handleBuyButton}>
                        <span>Купить билет</span>
                    </button>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="ticket-page" id="ticket-page-container">
                <div className="upper-text-container">
                    <span className="upper-text">{t("visitor")}</span><br />
                </div>
                <div className="FIO">
                    <span>{userLastName}</span><br />
                    <span>{userName}</span><br />
                </div>
                <div className="organization">
                    <span>{userCompany}</span>
                </div>
                <div className="QR-code">
                    <span>'QR-CODE'</span>
                </div>
            </div>
            <div className="button-container">
                <button className="download-button" onClick={downloadPdf}>
                    <span>{t("downloadPDF")}</span>
                    <Icon />
                </button>
            </div>
        </>
    );
};

export default Ticket;

