export const agrSegment =[
    {
        key: '1',
        text: 'Растениеводство',
        value: 'Растениеводство',
    },
    {
        key: '2',
        text: 'Животноводство',
        value: 'Животноводство',
    }
]

export const companySettings = [
    {
        key: '1',
        text: 'ООО "ПРОТЕКТФИД"',
        value: 'ООО "ПРОТЕКТФИД"',
    }
]

export const personSettings = [
    {
        key: '1',
        text: 'Иванов Иван Иванович',
        value: 'Иванов Иван Иванович',
    }
]

export const timeSettings = [
    {
        key: '1',
        text: '10:15-12:15',
        value: '10:15-12:15',
    },
    {
        key: '2',
        text: '12:30-14:15',
        value: '12:30-14:15',
    }
]

export const placeSettings = [
    {
        key: '1',
        text: 'Стенд B123',
        value: 'Стенд B123',
    },
    {
        key: '2',
        text: 'Стенд A123',
        value: 'Стенд A123',
    }
]
