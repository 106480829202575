import 'whatwg-fetch';
import { toastr } from 'react-redux-toastr';
import config, {AUTH_INVALID_MESSAGE, AUTH_INVALID_TOKEN, SERVICE_LOGICAL_ACCESS_DENIED} from '../../config/config';
import { closeAuth } from '../NavTopBar/actions';

export const REQUEST_USER_DATA = 'REQUEST_USER_DATA';
export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';
export const REQUEST_TOKEN = 'REQUEST_TOKEN';
export const RECEIVE_TOKEN = 'RECEIVE_TOKEN';
export const DROP_TOKEN = 'DROP_TOKEN';
export const PHONE_NUMBER = 'PHONE_NUMBER';

export const REQUEST_MOBILE_OTP = 'REQUEST_MOBILE_OTP';
export const RECEIVE_MOBILE_OTP = 'RECEIVE_MOBILE_OTP';

export const REQUEST_RESET_PWD_OTP = 'REQUEST_RESET_PWD_OTP';
export const RECEIVE_RESET_PWD_OTP = 'RECEIVE_RESET_PWD_OTP';

export const SET_AUTH_SCREEN = 'SET_AUTH_SCREEN';
export const AUTH_SCREEN_MAIN = 'AUTH_SCREEN_MAIN';
export const AUTH_SCREEN_AUTH_ENTER_OTP = 'AUTH_SCREEN_AUTH_ENTER_OTP';
export const AUTH_SCREEN_RESET_PWD_ENTER_DATA = 'AUTH_SCREEN_RESET_PWD_ENTER_DATA';
export const AUTH_SCREEN_RESET_PWD_ENTER_OTP = 'AUTH_SCREEN_AUTH_ENTER_OTP';
export const AUTH_SCREEN_SIGNUP = 'AUTH_SCREEN_SIGNUP';

export const setAuthScreen = (screenCode = 'AUTH_SCREEN_MAIN') => ({
    type: SET_AUTH_SCREEN,
    screenCode
});

export const requestUserData = (query = "") => ({
  type: REQUEST_USER_DATA,
  query
});

export const receiveUserData = (data) => ({
  type: RECEIVE_USER_DATA,
  data,
});

export const requestToken = () => ({
  type: REQUEST_TOKEN,
});

export const receiveToken = (userName, json) => ({
  type: RECEIVE_TOKEN,
  data: {
    userName,
    userDisplayName: json.user_display_name,
    token: json.token,
    loggedIn: !!json.token,
    message: json.message,
    onDate: new Date(),
  },
});

export const dropToken = () => ({
  type: DROP_TOKEN,
  data: {
    loggedIn: false,
    message: '',
    onDate: new Date(),
  },
});

export const requestMobileOTP = (query = "") => ({
    type: REQUEST_MOBILE_OTP,
    query
});

export const receiveMobileOTP = (data) => ({
    type: RECEIVE_MOBILE_OTP,
    data,
    screenCode: AUTH_SCREEN_AUTH_ENTER_OTP,
});

export const requestResetPwdOTP = (query = "") => ({
    type: REQUEST_RESET_PWD_OTP,
    query
});

export const receiveResetPwdOTP = (data) => ({
    type: RECEIVE_RESET_PWD_OTP,
    data,
    screenCode: AUTH_SCREEN_RESET_PWD_ENTER_OTP,
});

const process_api_error = (json, dispatch) => {
  console.error('UserData update error', json);
  dispatch(receiveUserData({ code: 401, message: json.message }));
  if (json.code === AUTH_INVALID_TOKEN) {
    dispatch(dropToken());
    toastr.error(AUTH_INVALID_MESSAGE);
  } else
  if (json.code === SERVICE_LOGICAL_ACCESS_DENIED)
    toastr.error(json.message);
  else
    toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
}

export const fetchUserData = (token) => (dispatch) => {
  dispatch(requestUserData());
  const headers = { Authorization: 'Bearer ' + token};

  return fetch(config.API_CURRENT_USER_URL, { headers: headers, credentials: 'same-origin' })
    .then((response) => response.json())
    .then((json) => {
      dispatch(receiveUserData(json));
    })
    .catch((ex) => {
      toastr.error('Ошибка сервера: ' + ex);
      dispatch(receiveUserData({ code: 401, message: ex }));
    });
}

export const fetchToken = (userName, userPassword, lang) => (dispatch) => {
  dispatch(requestToken());

  return fetch(config.API_TOKEN_URL + '?username=' + userName + '&password=' + userPassword, {
      method: 'post',
      headers: {
          'Content-Type': 'application/json',},
  })
    .then((response) => response.json())
    .then((json) => {
      dispatch(receiveToken(userName, json));
      if (json.token) {
        toastr.success(lang === "en" ? "Welcome, " + json.user_display_name + "!" : "Добро пожаловать, " + json.user_display_name + '!');
        dispatch(fetchUserData(json.token));
        dispatch(closeAuth());
      }
    })
    .catch((ex) => {
      dispatch(receiveToken(userName, { code: 401, message: ex }));
    });
}

export const updateUserData = (token, user_id, query, silent = false) => (dispatch) => {
  dispatch(requestUserData(query));

 const headers =  token ? { Authorization: 'Bearer ' + token } : {};
 let url = config.API_UPDATE_USER_DATA_URL + user_id;

  console.log('updateUserData action', user_id, query, url, token);

  const formData = new FormData();
  formData.append('query', query);

  return fetch(url, { method: 'post', headers, body: formData })
      .then((response) => response.json())
      .then((json) => {
        console.log('updateUserData responce', json);
        if (json.code === 200) {
          dispatch(receiveUserData(json.data));
          if (!silent)
            toastr.success(`Изменения сохранены`);
        } else {
          process_api_error(json, dispatch);
        }
      })
      .catch( (error_message) => {
        console.log('ошибка', error_message);
        toastr.error('Ошибка запроса: ' + String(error_message))
      });
}

export const putTicket = (token, ticketData) => (dispatch) => {

    const headers = {Authorization: 'Bearer ' + token};
    let url = config.API_CREATE_USER_TICKET + '?' +
        Object.keys(ticketData)
            .map((k) => k + '=' + encodeURIComponent(ticketData[k]))
            .join('&');

    return fetch(url, {method: 'post', headers})
        .then((response) => response.json())
        .then((json) => {
            if (json.status === 200) {
            } else {
                process_api_error(json, dispatch);
                console.warn('Ошибка создания запроса putTicket', url, ticketData, json);
            }
        })
        .catch((ex) => {
            console.error('Ошибка запроса: ' + String(ex));
        });
}

function open(url) {
    const win = window.open(url);
    if (win != null) {
        win.focus();
    }
}

export const loginByIdMTS = (params) => (dispatch) => {
    //dispatch(requestToken());

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Max-Age': '3600'
    };
    let url = config.API_MTS_ID_LOGIN_URL + '?' +
        Object.keys(params)
            .map((k) => k + '=' + params[k])
            .join('&');

    console.log('MTS ID url', url)

    open(url); return;

    return fetch(url, {method: 'get', headers})
        .then((response) => response.json())
        .then((json) => {
            //dispatch(receiveToken(userName, json));
            console.log(json)
        })
        .catch((ex) => {
            console.error(ex)
            //dispatch(receiveToken(userName, { code: 401, message: ex }));
        });
}

export const authGetOTP = (lang, app_code, phone) => (dispatch) => {
    dispatch(requestMobileOTP());

    let url = config.API_MOBILE_AUTH_OTP_URL;

    const formData = new FormData();
    formData.append('lang', lang);
    formData.append('app_code', app_code);
    formData.append('phone', phone);
    formData.append('debug', 1);

    console.log('authGetOTP', lang, app_code, phone);

    return fetch(url, {method: 'post', body: formData, credentials: 'same-origin'})
        .then((response) => response.json())
        .then((json) => {
            console.log('receive OTP', json);
            if (json.code === 200) {
                dispatch(receiveMobileOTP(json));
            } else
                process_api_error(json, dispatch);
        })
        .catch((ex) => {
            toastr.error('Ошибка запроса: ' + String(ex));
            dispatch(dropToken());
        });
}

export const authByOTP = (lang, app_code, phone, id_session, otp_code) => (dispatch) => {
    dispatch(requestToken());

    let url = config.API_MOBILE_AUTH_LOGIN_URL;

    const formData = new FormData();
    formData.append('lang', lang);
    formData.append('app_code', app_code);
    formData.append('phone', phone);
    formData.append('id_session', id_session);
    formData.append('otp_code', otp_code);
    formData.append('debug', 1);

    return fetch(url, {method: 'post', body: formData, credentials: 'same-origin'})
        .then((response) => response.json())
        .then((json) => {
            if (json.token) {
                dispatch(receiveToken(json.user_name, json));
                toastr.success(lang === "en"
                    ? "Welcome, " + json.user_display_name + "!"
                    : "Добро пожаловать, " + json.user_display_name + '!'
                );
                dispatch(fetchUserData(json.token));
                    dispatch(closeAuth());
            } else {
                process_api_error(json, dispatch);
                dispatch(dropToken());
            }
        })
        .catch((ex) => {
            toastr.error('Ошибка запроса: ' + String(ex));
            dispatch(dropToken());
        });
}

export const resetPasswordGetOTP = (lang, app_code, phone, login) => (dispatch) => {
    dispatch(requestResetPwdOTP());

    let url = config.API_EMAIL_RESET_PWD_OTP_URL;

    const formData = new FormData();
    formData.append('lang', lang);
    formData.append('app_code', app_code);
    formData.append('phone', phone);
    formData.append('login', login);
    formData.append('debug', 1);

    console.log('authGetOTP', lang, app_code, phone);

    return fetch(url, {method: 'post', body: formData, credentials: 'same-origin'})
        .then((response) => response.json())
        .then((json) => {
            console.log('receive OTP', json);
            if (json.code === 200) {
                dispatch(receiveResetPwdOTP(json));
            } else
                process_api_error(json, dispatch);
        })
        .catch((ex) => {
            toastr.error('Ошибка запроса: ' + String(ex));
            dispatch(dropToken());
        });
}

export const resetPasswordAuthByOTP = (lang, app_code, phone, login, id_session, otp_code) => (dispatch) => {
    dispatch(requestToken());

    let url = config.API_EMAIL_RESET_PWD_LOGIN_URL;

    const formData = new FormData();
    formData.append('lang', lang);
    formData.append('app_code', app_code);
    formData.append('phone', phone);
    formData.append('login', phone);
    formData.append('id_session', id_session);
    formData.append('otp_code', otp_code);
    formData.append('debug', 1);

    return fetch(url, {method: 'post', body: formData, credentials: 'same-origin'})
        .then((response) => response.json())
        .then((json) => {
            if (json.token) {
                dispatch(receiveToken(json.user_name, json));
                toastr.success(lang === "en"
                    ? "Welcome, " + json.user_display_name + "!"
                    : "Добро пожаловать, " + json.user_display_name + '!'
                );
                dispatch(fetchUserData(json.token));
                dispatch(closeAuth());
            } else {
                process_api_error(json, dispatch);
                dispatch(dropToken());
            }
        })
        .catch((ex) => {
            toastr.error('Ошибка запроса: ' + String(ex));
            dispatch(dropToken());
        });
}