
import React, {useEffect, useRef, useState} from 'react';
import {Dropdown} from "semantic-ui-react";
import InputMask from "react-input-mask";
import {useSelector} from "react-redux";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";

const PhoneCountryMask = (props) => {

    const { countries, loading, country, extraClass, phone, handleChangeField, nameCountry, namePhone, fluid, phoneMask } = props;

    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };


    const [state, setState] = useState({
        isoCountry: country ?? "RU",
        phoneMask: phoneMask ?? "+7 (r##) ###-##-##",
        phone: phone,
    });

    const ref = useRef(null);

    useEffect(() => {
        // when component mounts
        const phone_element = document.getElementById("masked_phone");

        if (phone_element && phone_element.value.length && phone_element.value !== state.phone) {
            setState({...state, phone: phone_element.value});
            handleChangeField(namePhone, phone_element.value);
        }
    }, [state.phoneMask]);

    function onChangeCountry(e, { value }) {
        const rows = countries.filter((row) => row.value === value);
        setState( { ...state, isoCountry: value, phoneMask: rows[0].phone_mask} );
        handleChangeField(nameCountry, value);
       // ref.current.focus();
    }

    function onChangePhone(e) {
        setState( { ...state, phone: e.target.value} );
        handleChangeField(namePhone, e.target.value);
    }

    //console.log('input ref', ref.current);

    return (
        <div className={`ui input phone-country-mask ${ extraClass + (fluid ? ' fluid' : '') }`}>
            <Dropdown
                id="dropdown_country"
                name={ nameCountry }
                options={ countries }
                loading = { loading }
                value={ state.isoCountry }
                onChange={ onChangeCountry }
                selection
                scrolling
                fluid
                search
                noResultsMessage={t("notFound")}
            />
            <InputMask
                mask={ state.phoneMask }
                maskChar="_"
                placeholder={t("phoneNumber")}
                id="masked_phone"
                name= { namePhone }
                value={ phone }
                onChange={ onChangePhone }
                type="tel"
                formatChars={{
                    '9': '',
                    '#': '[0-9]',
                    'r': '[0|1|2|3|4|5|8|9]',
                    'k': '[6|7]',
                }}
                inputRef={ref}
            />
        </div>
    );
};

export default PhoneCountryMask;
