import React, {useEffect, useState} from 'react';
import {Button, Dimmer, Form, Icon, Label, Loader, Message, Modal,} from 'semantic-ui-react';
import {useSelector} from 'react-redux';

import {isAuthVisible} from '../NavTopBar/reducer';
import {closeAuth} from '../NavTopBar/actions';
import {
    AUTH_SCREEN_AUTH_ENTER_OTP,
    AUTH_SCREEN_MAIN, AUTH_SCREEN_RESET_PWD_ENTER_DATA, AUTH_SCREEN_SIGNUP,
    authByOTP,
    authGetOTP,
    fetchToken,
    loginByIdMTS,
    setAuthScreen
} from './actions';
import './UserLogin.css';
import './style.css'
import {getAuthFetching, getAuthMode, getUserFetching, getUserLoginError} from './reducer';
import PhoneCountryMask from "../AddressCard/PhoneCountryMask";
import {ADDRESS_PHONE, ADDRESS_PHONE_COUNTRY, fetchCounties, setAddressFields} from "../AddressCard/actions";
import {Link} from "react-router-dom";
import {ReactComponent as AsiaExpoIcon} from '../../icons/asiaexpo2024-icon.svg';
import {getAddressFields, getCountries, isCountriesFetching} from "../AddressCard/reducer";
import _ from "lodash";
import translations from "../LanguageModal/translations";
import {getLanguage} from "../LanguageModal/reducer";

import {EmailSuggestions, FioSuggestions, PartySuggestions} from 'react-dadata';
import {setPopupClose} from "../../views/EditProfile/actions";
import {toastr} from "react-redux-toastr";
import config from "../../config/config";
import {DADATA_API_KEY} from "../../config/secrets";


export const cleanMessage = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");

export default function UserLogin (props) {

    const {userData, dispatch, mixpanel } = props;
    const showModal = useSelector((state) => isAuthVisible(state.navtopbar));
    const isFetching = useSelector((state) => getAuthFetching(state.userLogin));
    //const isFetchingAuth = useSelector((state) => getAuthFetching(state.userLogin));
    const messageLoginError= useSelector((state) => getUserLoginError(state.userLogin));
    const authMode = useSelector((state) => getAuthMode(state.userLogin));
    const isFetchingCountries = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));

    const defaultSettings = {
        phone: '',
        email: userData.login ?? '',
        password: '',
        emailError: false,
        passwordError: false,
        showPassword: false,
    };

    const [state, setState] = useState(defaultSettings);
    const [phoneNumber, setPhoneNumber] = useState("");

    console.log('UserLogin props & states Debug Mode', authMode, isFetching, phoneNumber);

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    let listPhoneCountries = [];

    listPhoneCountries =
        countries.countries ?
            Object.values(countries.countries).map((element, index) => (
                {
                    key:  index,
                    text: languageString === "en" ? element.country_en : element.country_ru,
                    flag: _.lowerCase(element.iso),
                    value: element.iso,
                    className: "dropdown-item",
                    phone_mask: element.phone_mask,
                }
            )) : [] ;

    useEffect(() => {

        // по умолчанию устанавливаем главное окно
        dispatch(setAuthScreen(AUTH_SCREEN_MAIN));

        // если стран нет, то запрашиваем их
        if (!countries || !countries.countries) {
            dispatch(fetchCounties());
        }

    }, []);

    useEffect(() => {

      if (mixpanel)
          mixpanel.track('Login Dialog Open', { ...userData });

      //setState(defaultSettings);
      //setContent(null);

    }, [showModal === true]);

    function validateForm() {
        setState({ ...state, emailError: !state.email.length, passwordError: !state.password.length});
        return (state.email.length && state.password.length);
    }

    function submitForm() {
        if (validateForm())
            dispatch(fetchToken(state.email, state.password, languageString));
            //setContent(null)
    }

    function handleSetShowPassword(show) {
        setState({...state, showPassword: show});
    }

    const handleChangeField = (fieldName, value) => {
        setPhoneNumber(value);
        //dispatch(authPhone(value))
    }

    // --------------------- MTC ID ---------------------------------------------------- //
    const [stateIdMTC, setStateIdMTC] = React.useState({
        open: false,
        loading: false,
        params: {},
    });

    const closeIdMTCloseIdMTC = () => {
        setStateIdMTC({open: false, loading: false, params: {}});
    }

    const setLoadedIdMTC = () => {
        setStateIdMTC({...stateIdMTC, loading: false});
    }

    const handleLoginByIdMTS = () => {

        function makeId(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }

        const params = {
            formData: {
                client_id: 'ASIAEXPO',
                scope: 'phone',
                state: makeId(24),
                redirect_uri: encodeURIComponent('https://cms.asiaexpo.space/wp-json/app/v1/mobile-id-callback'),
                response_type: 'code',
                login_hint: '79180229059',
                prompt: 'login',
            },
            iframe: true,
            language: 'ru',
            route: window.location.hash.slice(1),
            stateLoading: stateIdMTC.loading,
            handleSetLoaded: setLoadedIdMTC,
            handleClose: closeIdMTCloseIdMTC,
        }

        console.log('pressed login MTS ID', params);

        //setStateIdMTC({open: true, loading: true, params: params});

        dispatch(loginByIdMTS(params.formData));
    }
    // --------------------- end of MTC ID ---------------------------------------------------- //

    const ConfirmCode = (props) => {


        //const [seconds, setSeconds] = useState(String(authMode.data.otp_get_timeout));

        const [timeoutRepeat, setTimeoutRepeat] = useState(Number(authMode.data.otp_get_timeout));
        const [timeoutWait, setTimeoutWait] = useState(Number(authMode.data.otp_wait_timeout));

        const [textColor, setTextColor] = useState("#A7AFB7");
        const [inputs, setInputs] = useState(['', '', '', '']);

        // запускаем таймер ожидания для повторной отправки кода
        useEffect(() => {

            let time = Number(timeoutRepeat);
            const intervalId = setInterval(() => {
                console.log('timeoutRepeat', Number(timeoutRepeat), time)
                time--;
                setTimeoutRepeat(prevTimeoutRepeat => prevTimeoutRepeat - 1);
                if (time === 0)
                    clearInterval(intervalId);
            }, 1000);
            console.log('timeoutRepeat', timeoutRepeat, time)
            return () => clearInterval(intervalId);

        }, []);

        // запускаем таймер ожидания для воода полученного кода
        useEffect(() => {
            if (timeoutWait > 0) {
                let time = timeoutWait;
                const intervalId = setInterval(() => {
                    console.log('setTimeoutWait', timeoutWait, time)
                    time--;
                    setTimeoutWait(time /*prevTimeoutWait => prevTimeoutWait - 1*/);
                    if (time === 0) {
                        clearInterval(intervalId);
                        toastr.error('Истекло время ожидания ввода кода подтверждения. Пожалуйста, запросите код повторно');
                        // Verification code entry timed out. Please request code again
                        dispatch(setAuthScreen(AUTH_SCREEN_MAIN));
                    }
                }, 1000);
                return () => clearInterval(intervalId);
            }
        }, []);

        function handleKeyPress(e, index) {
            if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(e.key)) {
                if (e.key === 'Backspace') {
                    if (inputs[index] === '') {
                        if (index > 0) {
                            document.getElementById(`input-number${index - 1}`).focus();
                        }
                    }
                } else {
                    console.log('key press', e);
                    if (inputs.length < 1) {
                        e.preventDefault();
                    }
                }
            } else {
                e.preventDefault();
            }
        }

        const handleClick = () => {
            //setSeconds(authMode.data.otp_get_timeout);
            setTextColor('#A7AFB7');
            dispatch(authGetOTP(languageString, config.APP_CODE, phoneNumber));
        }

        const [error, setError] = useState(false)
        const handleInput = (index, value) => {
            const newInputs = [...inputs];
            newInputs[index] = value;

            if (value && index < inputs.length - 1) {
                document.getElementById(`input-number${index + 1}`).focus();
            } else if (!value && index > 0) {
                document.getElementById(`input-number${index - 1}`).focus();
            }

            setInputs(newInputs);

            const enteredCode = newInputs.join("");
            if (enteredCode.length === 4) {
                console.log('send code', enteredCode);
                dispatch(authByOTP(languageString, config.APP_CODE, phoneNumber, authMode.data.id_session, enteredCode));
            }
        }

        const handleReset = () => {
            setInputs(["", "", "", ""])
            document.getElementById('input-number0').focus();
        }

        function testJump(x){
            var ml = ~~x.getAttribute('maxlength');
            if(ml && x.value.length >= ml){
                do{
                    x = x.nextSibling;
                }
                while(x && !(/text/.test(x.type)));
                if(x && /text/.test(x.type)){
                    x.focus();
                }
            }
        }
        return (
            <div className='main-block5'>
                <AsiaExpoIcon/>
                <div className='container-numbers'>
                    <span className='text-for-label'>{t("enter4symb")}</span>
                    <span className='number-phone' id='changePhone'>{phoneNumber}</span>
                </div>
                <button id='btnCode' onClick={handleClick} disabled={timeoutRepeat > 0}>
                <span className='text-again'
                      style={{color: timeoutRepeat === 0 ? 'black' : textColor}}>{timeoutRepeat === 0 ? t("resend") : t("resend") + " " + `(${timeoutRepeat}` + " " + t("sec") + ")"}</span>
                </button>
                {(error) && <div style={{color: 'red', fontFamily: 'Muller-Bold'}}>Неверный код</div>}
                <div className='container-inputs'>
                    {
                        inputs.map((value, index) => (
                            <input
                                key={index}
                                id={`input-number${index}`}
                                maxLength='1'
                                className='input-number'
                                type="text"
                                autoComplete="off"
                                onInput={e => testJump(e.target)}
                                value={value}
                                onKeyPress={(e) => handleKeyPress(e, index)}
                                onChange={(e) => handleInput(index, e.target.value)}
                            />
                        ))
                    }
                </div>
                <div onClick={() => handleReset()} style={{color: '#A7AFB7', cursor: 'pointer'}}>Очистить поля ввода
                </div>
            </div>
        )
    }

    const Registration = (props) => {
        const fields = useSelector((state) => getAddressFields(state.address));

        const formFields = [
            'first_name',
            'last_name',
            'email',
            'phone_country',
            'phone',
            'company',
        ];

        const [errors, setErrors] = useState({});

        const [email, setEmail] = useState("");
        const [surname, setSurname] = useState("");
        const [name, setName] = useState("");
        const [company, setCompany] = useState("");
        const [phone, setPhone] = useState("")
        const [phoneCountry, setPhoneCountry] = useState("RU")

        useEffect(() => {
            mountDirectField([
                {name: 'countryPhoneCode', value: formData.phone_country},
                {name: 'phone', value: formData.phone}
            ]);
            dispatch(setPopupClose());
        }, []);


        const [formData, setFormData] = useState({
            first_name: surname,
            last_name: name,
            email: email,
            phone: phone,
            phone_country: phoneCountry,
            company: company,
        });


        const phoneMask = listPhoneCountries.find(item => item.value === formData.phone_country)?.phone_mask;

        const [mailValid, setMailValid] = useState(false);


        const handleFirstNameChange = (value) => {
            validate('first_name', value.value);
            setFormData({
                ...formData,
                first_name: value.value,
            });
        }

        const handleLastNameChange = (value) => {
            validate('last_name', value.value);
            setFormData({
                ...formData,
                last_name: value.value,
            });
        }

        const handleEmailChange = (value) => {
            validate('email', value.value);
            setFormData({
                ...formData,
                email: value.value,
            });
        }

        const handleCompanyChange = (value) => {
            validate('company', value.value);
            setFormData({
                ...formData,
                company: value.value,
            });
        }

        const validateFields = () => {
            formFields.forEach(field => {
                validate(field, formData[field]);
            })
        }


        const handleChange = (e) => {
            const { name, value } = e.target;
            validate(name, value);
            setFormData({
                ...formData,
                [name]: value,
            });

        }

        const handleChangeField = (fieldName, value) => {
            setFormData({
                ...formData,
                phone: value.value,
            });
        }


        //Проверка пчоты
        const isValidEmail = (email) => {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        //Обновление полей с телефонной маской
        function updateField() {
            formData.phone_country = fields[ADDRESS_PHONE_COUNTRY];
            formData.phone = fields[ADDRESS_PHONE];
            dispatch(setAddressFields(fields));
        }

        //Заполнение полей с телефонной масокй при загрузке страницы
        const mountDirectField = (data) => {
            data.forEach((element) => {
                fields[element.name] = element.value;
            });
            updateField();
        }

        //Изменение полей при редактировании маски
        const setDirectField = (name, data) => {
            if (name === 'countryPhoneCode') {
                fields['phone'] = '';
            }
            fields[name] = data;
            updateField();
        }

        const handleSubmitRegistration = () => {
            if (!formData.first_name || !formData.last_name || !formData.email || !mailValid || !formData.company || !formData.phone) {
                toastr.warning(t("validFields"));
                console.log("formData", formData)
            } else {
                console.log('регистрация успешна')
            }
        }

        const validate = (fieldName, value) => {
            let fieldErrors = {};
            if (fieldName === 'first_name') {
                if (!value) {
                    fieldErrors = { [fieldName]: t("validSurname")};
                } else {
                    fieldErrors = { [fieldName]: ''};
                }
            }
            if (fieldName === 'last_name') {
                if (!value) {
                    fieldErrors = { [fieldName]: t("validName")};
                } else {
                    fieldErrors = { [fieldName]: ''};
                }
            }
            if (fieldName === 'email') {
                if (!value) {
                    fieldErrors = { [fieldName]: t("validMailOne")};
                } else if (!isValidEmail(value)) {
                    fieldErrors = { [fieldName]: t("validMailTwo")};
                } else {
                    fieldErrors = { [fieldName]: ''};
                    setMailValid(true)

                }
            }
            if (fieldName === 'phone') {
                if (!value) {
                    fieldErrors = { [fieldName]: t("validNumber")};
                } else {
                    fieldErrors = { [fieldName]: ''};
                }
            }
            if (fieldName === 'company') {
                if (!value) {
                    fieldErrors = { [fieldName]: t("validCompany")};
                } else {
                    fieldErrors = { [fieldName]: ''};
                }
            }

            setErrors((prevErrors) => ({ ...prevErrors, ...fieldErrors }));
        }

        return (
            <div className='main-block-registration'>
                <div className='container-text-registration'>

                    <AsiaExpoIcon/>
                    <span className='text-for-label'>{t("toReg")}</span>
                </div>
                <div className='container-inputs-registration'>
                    <div className='registration-container'>
                        <FioSuggestions
                            token={DADATA_API_KEY}
                            inputProps={{
                                className: 'registration-input',
                                name: 'first_name',
                                id: 'first_name',
                                placeholder: t("surname"),
                                required: true,
                                onChange: handleChange
                            }}
                            defaultQuery={formData.first_name}
                            count='4'
                            filterParts={['surname']}
                            filterLanguage={languageString}
                            onChange={handleFirstNameChange}
                        />
                    </div>
                    {errors.first_name && <span className='edit-error'>{errors.first_name}</span>}

                    <div className='registration-container'>
                        <FioSuggestions
                            token={DADATA_API_KEY}
                            inputProps={{
                                className: 'registration-input',
                                name: 'last_name',
                                id: 'last_name',
                                placeholder: t("name"),
                                required: true,
                                onChange: handleChange
                            }}
                            defaultQuery={formData.last_name}
                            count='4'
                            filterParts={['name']}
                            filterLanguage={languageString}
                            onChange={handleLastNameChange}
                        />
                    </div>
                    {errors.last_name && <span className='edit-error'>{errors.last_name}</span>}

                    <div className='registration-container'>
                        <EmailSuggestions
                            token={DADATA_API_KEY}
                            inputProps={{
                                className: 'registration-input',
                                name: 'email',
                                id: 'email',
                                placeholder: t("mail"),
                                required: true,
                                onChange: handleChange
                            }}
                            defaultQuery={formData.email}
                            count='4'
                            onChange={handleEmailChange}
                        />
                    </div>
                    {errors.email && <span className='edit-error'>{errors.email}</span>}

                    <div className='registration-container'>
                        <PhoneCountryMask
                            extraClass="registration-input-phone"
                            name="phone"
                            countries={listPhoneCountries}
                            country={formData.phone_country}
                            loading={isFetchingCountries}
                            phone={formData.phone}
                            phoneMask={phoneMask}
                            handleChangeField={setDirectField}
                            nameCountry={ADDRESS_PHONE_COUNTRY}
                            namePhone={ADDRESS_PHONE}
                            fluid={true}
                            required
                        />
                    </div>
                    {errors.phone && <span className='edit-error'>{errors.phone}</span>}

                    <div className='registration-container'>
                        <PartySuggestions
                            token={DADATA_API_KEY}
                            inputProps={{
                                className: 'registration-input',
                                name: 'company',
                                id: 'company',
                                placeholder: t("company"),
                                required: true,
                                onChange: handleChange
                            }}
                            defaultQuery={formData.company}
                            count='4'
                            onChange={handleCompanyChange}
                            filterLanguage={languageString}
                        />
                    </div>
                    {errors.company && <span className='edit-error'>{errors.company}</span>}
                </div>
                <div className='container-button-registration'>
                    <Button className='button-confirm-registration' onClick={() => handleSubmitRegistration()}>
                        {t("registration")}
                    </Button>
                </div>
            </div>
        )
    }

    const handleSendCode = () => {
        if (!phoneNumber) {
            toastr.warning(t("validNumber"))
        } else {
            dispatch(authGetOTP(languageString, config.APP_CODE, phoneNumber));


/*                .then(() => {
                    if (authMode.code === 200) {
                        setContent(<ConfirmCode/>)
                        console.log(authMode.code)
                    } else if (authMode.status === 400) {
                        setContent(null)
                        console.log(authMode.code)
                        console.log(authMode.status)
                    } else {
                        setContent(null)
                    }

                })
                .catch(() => {
                    if (authMode.status === 400) {
                        setContent(null)
                        console.log(authMode.code)
                        console.log(authMode.status)
                    } else {
                        setContent(null)
                    }
                });*/

        }
    }

    const onClose = () => {
        dispatch(closeAuth())
        dispatch(setAuthScreen('AUTH_SCREEN_MAIN'))
    }

    return (
        <Modal
            dimmer
            closeIcon
            open={showModal}
            onClose={() => onClose()}
            centered
            closeOnDimmerClick
            className="user-login"
        >
            { authMode.mode === AUTH_SCREEN_AUTH_ENTER_OTP ? <ConfirmCode props={{phoneNumber, ...props}}/> : '' }
            { authMode.mode === AUTH_SCREEN_SIGNUP ? <Registration props={{phoneNumber, ...props}}/> : '' }
            { authMode.mode === AUTH_SCREEN_MAIN ?
                <>
                    <div className='main-block3'>
                        <AsiaExpoIcon style={{marginTop: '5%'}}/>

                        <span className='text-for-label'>{t("toLogApp")}</span>

                        <PhoneCountryMask
                            handleChangeField={handleChangeField}
                            extraClass="shipping-field shipping-phone"
                            countries={listPhoneCountries}
                            loading={isFetchingCountries}
                            country="RU"
                            phone={phoneNumber}
                            nameCountry={ADDRESS_PHONE_COUNTRY}
                            namePhone={ADDRESS_PHONE}
                            fluid={false}
                        />

                        <div className='container-button-confirm'>
                            <Link className="button-confirm-code"
                            >
                                <button
                                    className='text-for-link'
                                    onClick={() => handleSendCode()}
                                >{t("sendCode")}
                                </button>
                            </Link>
                        </div>
                        <div className="text-or">{t("or")}</div>

                    </div>
                    <Modal.Content>
                        <Modal.Description>
                            {messageLoginError !== '' ? (
                                <Message
                                    negative
                                    icon="user times"
                                    header="Вы не авторизованы"
                                    content={cleanMessage(messageLoginError)}
                                />
                            ) : ''}
                            <Form error={state.emailError || state.passwordError} onSubmit={submitForm}
                                  loading={Boolean(isFetching)}>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        icon="user"
                                        iconPosition="left"
                                        placeholder={t("loginOrMail")}
                                        onChange={(e) => setState({...state, email: e.target.value})}
                                        error={state.emailError}
                                        type="email"
                                        name="login"
                                        autoComplete="on"
                                        value={state.email}
                                        id='input-numbers'
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        placeholder={t("password")}
                                        onChange={(e) => setState({...state, password: e.target.value})}
                                        error={state.passwordError}
                                        type={state.showPassword ? "text" : "password"}
                                        name="password"
                                        autoComplete="on"
                                        value={state.password}
                                        iconPosition="left"
                                        labelPosition="right"
                                        id="input-numbers">
                                        <Icon name="key"/>
                                        <input/>
                                        <Label
                                            icon={state.showPassword ? "eye" : "eye slash"}
                                            onClick={() => handleSetShowPassword(!state.showPassword)}
                                            className="user-login-toggle-show-password"
                                            id='eye-icon-id'
                                        />
                                    </Form.Input>
                                </Form.Field>
                                <div className="container-buttons-auth">
                                    <button
                                        disabled={state.email === '' || state.password === ''}
                                        className={state.email === '' || state.password === '' ? "login-form-submit-button-disabled" : "login-form-submit-button"}
                                        onClick={submitForm}

                                    >
                                        {t("enter")}
                                    </button>
                                    <button
                                        className="login-form-submit-button"
                                        onClick={() => dispatch(setAuthScreen(AUTH_SCREEN_SIGNUP))}
                                    >
                                        {t("registration")}
                                    </button>
                                    <span className='forgot-pass' onClick={() => dispatch(setAuthScreen(AUTH_SCREEN_RESET_PWD_ENTER_DATA))}>Забыли пароль?</span>
                                </div>
                                <br/>
                                {/*<div className="container-line">*/}
                                {/*    <div className="line-or"></div>*/}
                                {/*    <div className="text-or">{t("or")}</div>*/}
                                {/*    <div className="line-or"></div>*/}
                                {/*</div>*/}
                                {/*<div className='btn-mts-id'>*/}
                                {/*    <Button className='log-with-mts-id' onClick={() => handleLoginByIdMTS()}>*/}
                                {/*        войти с <MTSIcon className="mts-icon"/>id*/}
                                {/*    </Button>*/}
                                {/*    {*/}
                                {/*        stateIdMTC.open ?*/}
                                {/*        <FormIdMTC*/}
                                {/*            {...stateIdMTC.params}*/}
                                {/*            {...props}*/}
                                {/*        />*/}
                                {/*        : ''*/}
                                {/*    }*/}
                                {/*</div>*/}
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                </> : '' }

            <Dimmer active={isFetching} inverted>
                <Loader active={isFetching}>{t("loadInfo")}...</Loader>
            </Dimmer>
        </Modal>


    );
}


