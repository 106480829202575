import React, {useEffect} from 'react';
import ReactDOM from "react-dom/client";
import {Provider, useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {HistoryRouter} from "redux-first-history/rr6";
import {PersistGate} from 'redux-persist/integration/react';
import {Loader} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import {persistor, routerHistory, store} from './configureStore';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import Home from './views/Home';
import Products from './views/Products';
import Categories from './views/Categories';
import Product from './views/Product';
import Cart from './views/Cart';
import Search from './views/Search';
import CheckoutStepShipping from './views/Checkout_1_StepShipping';
import CheckoutStepPayment from './views/Checkout_2_StepPayment';
import FAQ from './views/FAQ';
import Instructions from './views/Instructions';
import DescriptionCongress from "./views/DescriptionCongress";
import CulturalProgam from "./views/Сultural_program";
import ArchiveEvents from './views/ArchiveEvents';
import TravelPlan from './views/TravelPlan';
import Exposition from './views/Exposition';
import CongressAE2023 from "./views/CongressAE2023";
import HackathonAE from "./views/HackathonAE";
import BusinessMissionAE from "./views/BusinessMissionAE";
import EventsAE from "./views/EventsAE";
import Authorization from "./views/Authorization";
import Registration from "./views/Registration";
import ConfirmationCode from "./views/ConfirmationCode";
import Bookmarks from "./views/Bookmarks";
import Profile from './views/Profile';
import EditProfile from "./views/EditProfile";
import Scan from "./views/Scan";
import Support from "./views/Support";
import Company from "./views/Company";
import Ticket from "./views/Ticket";
import Exponent from "./views/Exponent";
import AsiaMeet from "./views/AsiaMeet";
import FormMeeting from "./views/FormMeeting";
import Notifications from "./views/Notifications";
import Exponents from "./views/Exponents";
import Exponents_card from "./views/Exponents_card";
import PdfFile from "./views/PdfFile";

import './index.css';
import CheckoutStepConfirm from "./views/Checkout_3_StepConfirm";
import Orders from "./views/Orders";
import Order from "./views/Order";
import {closeMenu} from "./components/NavBottomBar/actions";
import {closeSearch} from "./components/NavTopBar/actions";
import {isSearchVisible} from "./components/NavTopBar/reducer";
import {getAuthToken, getUserData, getUserLoggedIn} from "./components/UserLogin/reducer";
import {getLocationData} from "./components/GeoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";
import {isMenuVisible} from "./components/NavBottomBar/reducer";
import {isArray} from "lodash";
import BusinessProgram from "./views/BusinessProgram";
import EventBP from "./views/BusinessProgram_event";

import BusinessProgram23year from "./views/BusinessProgram2023Year";
import EventBP23Year from "./views/BusinessProgram2023Year_event";
import CulturalProgram2023 from "./views/CulturalProgram2023";
import ExponentsArchive from "./views/ExponentsArchive";
import ExponentCardArchive from "./views/ExponentCardArchive";

const root = ReactDOM.createRoot(document.getElementById("root"));

console.log(`App "${process.env.REACT_APP_NAME}" version ${process.env.REACT_APP_VERSION} is running in ${process.env.REACT_APP_ENV} mode`)

const withRouter = (Component) => (props) => {

    const router = useSelector((state) => state.router);

    const defaultProps = {
        navigate: useNavigate(),
        dispatch: useDispatch(),
        searchVisible: useSelector((state) => isSearchVisible(state.navtopbar)),
        menuVisible: useSelector((state) => isMenuVisible(state.navbottombar)),
        userData: useSelector((state) => getUserData(state.userLogin)),
        userLocation: useSelector((state) => getLocationData(state.userLocation)),
        isLoggedIn: useSelector((state) => getUserLoggedIn(state.userLogin)),
        token: useSelector((state) => getAuthToken(state.userLogin)),
        mixpanel: useMixpanel(),
        routeAction: router.action,
        location: router.location,
        previousLocations: router.previousLocations,
        isPageReloaded: router.action === 'POP' && isArray(router.previousLocations) && _.size(router.previousLocations) === 1,
    }
    const {menuVisible, searchVisible, dispatch} = defaultProps;

    useEffect(() => {
        if (menuVisible)
            dispatch(closeMenu());

        if (searchVisible)
            dispatch(closeSearch());
    }, []);

    return <Component {...defaultProps}/>;
}

root.render(
  <Provider store={store}>
    <PersistGate loading={<Loader active />} persistor={persistor}>
      <HistoryRouter history={routerHistory}>
        <App>
          <Routes>
              <Route exact path={`/`} Component={withRouter(Home)} />
              <Route path={`/search/:search`} Component={withRouter(Search)} />
              <Route path={`/faq`} Component={withRouter(FAQ)} />
              <Route path={`/instructions`} Component={withRouter(Instructions)} />
              <Route path={`/description-congress`} Component={withRouter(DescriptionCongress)} />
              <Route path={'/cultural-program'} Component={withRouter(CulturalProgam)}/>
              <Route path={'/archive-events'} Component={withRouter(ArchiveEvents)}/>
              <Route path={'/archive-events/congress-asiaexpo-2023'} Component={withRouter(CongressAE2023)}/>
              <Route path={'/archive-events/congress-asiaexpo-2023/exponents'} Component={withRouter(ExponentsArchive)}/>
              <Route path={'/archive-events/congress-asiaexpo-2023/exponents/exponents-card/:exponentId'} Component={withRouter(ExponentCardArchive)}/>
              <Route path={'/hackathon-asiaexpo'} Component={withRouter(HackathonAE)}/>
              <Route path={'/business-mission-asiaexpo'} Component={withRouter(BusinessMissionAE)}/>
              <Route path={'/events-asiaexpo'} Component={withRouter(EventsAE)}/>
              <Route path={'/auth'} Component={withRouter(Authorization)}/>
              <Route path={'/registration'} Component={withRouter(Registration)}/>
              <Route path={'/confirmation-code'} Component={withRouter(ConfirmationCode)}/>
              <Route path={'/bookmarks'} Component={withRouter(Bookmarks)}/>
              <Route path={'/travel-plan'} Component={withRouter(TravelPlan)}/>
              <Route path={'/exposition'} Component={withRouter(Exposition)}/>
              <Route path={'/profile'} Component={withRouter(Profile)} />
              <Route path={'/profile/edit'} Component={withRouter(EditProfile)} />
              <Route path={'/profile/scan'} Component={withRouter(Scan)} />
              <Route path={'/support'} Component={withRouter(Support)} />
              <Route path={'/profile/company'} Component={withRouter(Company)} />
              <Route path={'/ticket'} Component={withRouter(Ticket)} />
              <Route path={'/schedule'} Component={withRouter(BusinessProgram)} />
              <Route path={'/profile/exponents'} Component={withRouter(Exponent)} />
              <Route path={'/asiameet'} Component={withRouter(AsiaMeet)} />
              <Route path={'/asiameet/form-meeting'} Component={withRouter(FormMeeting)} />
              <Route path={'/notifications'} Component={withRouter(Notifications)} />
              <Route path={'/business-program'} Component={withRouter(BusinessProgram)} />
              <Route path={'/business-program/event/:forumId'} Component={withRouter(EventBP)} />
              <Route path={'/exponents'} Component={withRouter(Exponents)} />
              <Route path={`/exponents/exponents-card/:exponentId`} Component={withRouter(Exponents_card)} />
              <Route path={`/archive-events/congress-asiaexpo-2023/business-program-archive`} Component={withRouter(BusinessProgram23year)} />
              <Route path={`/archive-events/congress-asiaexpo-2023/business-program-archive/event/:forumId`} Component={withRouter(EventBP23Year)} />
              <Route path={`/archive-events/congress-asiaexpo-2023/cultural-program`} Component={withRouter(CulturalProgram2023)} />
              <Route path={'/policy'} Component={withRouter(PdfFile)} />
          </Routes>
        </App>
      </HistoryRouter>
    </PersistGate>
  </Provider>
);

//registerServiceWorker();

export default withRouter;

