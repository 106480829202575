import React, {useEffect, useState} from 'react'

import { Select, Input } from 'semantic-ui-react'

import "./style.css"

import {Link} from "react-router-dom";

import { ReactComponent as AsiaExpoIcon } from '../../icons/asiaexpo2024-icon.svg';


export default function Registration() {
    const countryOptions = [
        { key: 'ru', value: 'ru', flag: 'ru', text: 'Русский' },
        { key: 'en', value: 'en', flag: 'us', text: 'English' }
    ]


    const [email, setEmail] = useState("");
    const [surname, setSurname] = useState("");
    const [emailDirty, setEmailDirty] = useState(false);
    const [surnameDirty, setSurnameDirty] = useState(false);
    const [emailError, setEmailError] = useState("Поле ввода не может быть пустым");
    const [surnameError, setSurnameError] = useState("Поле ввода не может быть пустым");

    const [name, setName] = useState("");
    const [nameDirty, setNameDirty] = useState(false);
    const [nameError, setNameError] = useState("Поле ввода не может быть пустым");

    const [company, setCompany] = useState("");
    const [companyDirty, setCompanyDirty] = useState(false);
    const [companyError, setCompanyError] = useState("Поле ввода не может быть пустым");

    const [phone, setPhone] = useState("");
    const [phoneDirty, setPhoneDirty] = useState(false);
    const [phoneError, setPhoneError] = useState("Поле ввода не может быть пустым");

    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        if (emailError || surnameError || nameError || companyError || phoneError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }, [emailError, surnameError, nameError, companyError, phoneError]);


    const nameHandler = (e) => {
        setName(e.target.value);
        if(!e.target.value) {
            setNameError("Поле ввода не может быть пустым");
        }
         else {
            setNameError("");
        }
    }

    const surnameHandler = (e) => {
        setSurname(e.target.value);
        if(!e.target.value) {
            setSurnameError("Поле ввода не может быть пустым");
        }
        else {
            setSurnameError("");
        }
    }

    const companyHandler = (e) => {
        setCompany(e.target.value);
        if(!e.target.value) {
            setCompanyError("Поле ввода не может быть пустым");
        }
        else {
            setCompanyError("");
        }
    }

    const emailHandler = (e) => {
        setEmail(e.target.value);
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setEmailError("Некорректный email адрес")
        } else {
            setEmailError("")
        }
    }

    const phoneHandler = (e) => {
        setPhone(e.target.value);
        const re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
        if (!re.test(String(e.target.value))) {
            setPhoneError("Некорректный номер телефона")
        } else {
            setPhoneError("")
        }


    }


    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'email':
                setEmailDirty(true);
                break;
            case 'surname':
                setSurnameDirty(true);
                break;
            case 'name':
                setNameDirty(true);
                break;
            case 'company':
                setCompanyDirty(true);
                break;
            case 'phone':
                setPhoneDirty(true);
                break;
        }
    }

    return (
        <div className='main-block4'>
            <div className='container1'>
                <Select options={countryOptions} placeholder="Выберите язык" style={{
                    borderRadius: '12px',
                    background: '#D9D9D9',
                    textColor: '#000000',
                    fontFamily: 'Muller'
                }}/>

                <AsiaExpoIcon/>
                <span className='text-for-label'>Зарегистрируйтесь для входа в приложение</span>
            </div>
            <div className='container-inputss'>
                {(surnameDirty && surnameError) && <div style={{color: 'red', fontFamily: 'Muller'}}>{surnameError}</div>}
                <Input onChange={e => surnameHandler(e)} onBlur={e => blurHandler(e)} name='surname' id='input-number12' type='text' placeholder='Фамилия*' style=
                    {{
                        width: '100%',
                        maxWidth: '65vw',
                        fontSize: '14px'
                    }}/>
                {(nameDirty && nameError) && <div style={{color: 'red', fontFamily: 'Muller'}}>{nameError}</div>}
                <Input onChange={e => nameHandler(e)} value={name} onBlur={e => blurHandler(e)} name='name' id='input-number12' type='text' placeholder='Имя*' style=
                    {{
                        width: '100%',
                        maxWidth: '65vw',
                        fontSize: '14px'
                    }}/>
                {(emailDirty && emailError) && <div style={{color: 'red', fontFamily: 'Muller'}}>{emailError}</div>}
                <Input onChange={e => emailHandler(e)} value={email} onBlur={e => blurHandler(e)} name='email' type='text'  id='input-number12' placeholder='E-mail*' style=
                    {{
                        width: '100%',
                        maxWidth: '65vw',
                        fontSize: '14px'
                    }}/>
                {(phoneDirty && phoneError) && <div style={{color: 'red', fontFamily: 'Muller'}}>{phoneError}</div>}
                <Input onChange={e => phoneHandler(e)} value={phone} onBlur={e => blurHandler(e)} name='phone' type='text' id='input-number12' placeholder='Телефон*' style=
                    {{
                        width: '100%',
                        maxWidth: '65vw',
                        fontSize: '14px'
                    }}/>
                {(companyDirty && companyError) && <div style={{color: 'red', fontFamily: 'Muller'}}>{companyError}</div>}
                <Input onChange={e => companyHandler(e)} value={company} onBlur={e => blurHandler(e)} name='company' type='text' id='input-number12' placeholder='Компания*' style=
                    {{
                        width: '100%',
                        maxWidth: '65vw',
                        fontSize: '14px'
                    }}/>
            </div>
            <div className='container-buttons'>
                <Link className="button2" to='/'>
                    <button disabled={!formValid} className={formValid ? 'text-for-link-registration' : 'text-for-link-registration-disabled'}>Регистрация</button>
                </Link>
            </div>
        </div>
    )
}