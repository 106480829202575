import React, {useEffect, useState} from 'react';
import './style.css';
import {Button} from 'semantic-ui-react';
import {toastr} from "react-redux-toastr";
import { v4 as uuidv4 } from 'uuid';

import CalendarIcon from "../../icons/calendar-icon.png";
import {useSelector} from "react-redux";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import {getExponents, getSegments} from "../Exponents/reducer";
import {EXPONENTS_PAGE_COUNT, fetchExponents, fetchSegments} from "../Exponents/action";

export default function AsiaMeet(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };
    const { navigate, dispatch } = props;
    const [isPlanedMeeting, setIsPlanedMeeting] = useState(true);
    const [applications, setApplications] = useState([
        {
            time: '10:15-12:15',
            company: 'ООО "ПРОТЕКТФИД"',
            person: 'Иванов Иван Иванович',
            place: 'Стенд B123',
            id: uuidv4()
        },
        {
            time: '12:30-14:15',
            company: 'ООО "ПРОТЕКТФИД"',
            person: 'Алексеев Алексей Алексеевич',
            place: 'Стенд A123',
            id: uuidv4()
        },
        {
            time: '15:15-17:45',
            company: 'ООО "ПРОТЕКТФИД"',
            person: 'Андреев Андрей Андреевич',
            place: 'Стенд С123',
            id: uuidv4()
        },

    ]);

    const [plannedMeetings, setPlannedMeetings] = useState([]);

    const deleteApplication = (id) => {
        setApplications(applications.filter((meeting) => meeting.id !== id))
    }

    const addMeeting = (newMeeting) => {
        setPlannedMeetings([...plannedMeetings, newMeeting])
        deleteApplication(newMeeting.id)
    }

    return (
        <div className="asiameet-view">
            <div className="asiameet-selector">
                <div
                    onClick={() => setIsPlanedMeeting(true)}
                    className={"asiameet-selector-item" + (isPlanedMeeting ? " selector-active-1" : "")}
                >
                    {t("planned")}
                </div>
                <div
                    onClick={() => setIsPlanedMeeting(false)}
                    className={"asiameet-selector-item" + (!isPlanedMeeting ? " selector-active-2" : "")}
                >
                    {t("requests")}
                </div>
            </div>

            {isPlanedMeeting && (
                <Button
                    className="asiameet-btn"
                    content={t("meetka")}
                    onClick={() => navigate('./form-meeting')}
                    primary
                />

            )}
            <div className="asiameet-content">
                {isPlanedMeeting ? (
                   plannedMeetings.map((meeting) => (
                       <div className="asiameet-card-planned">
                           <div className="asiameet-card-time">
                               <div className="asiameet-card-time-container">
                                   <img src={CalendarIcon} className="asiameet-card-time-icon" />
                                   <div className="asiameet-card-time-text">{meeting.time}</div>
                               </div>
                           </div>
                           <div>
                               <div className="asiameet-card-container">
                                   <div className="asiameet-card-text-container">
                                       <div className="asiameet-card-text">{meeting.place}</div>
                                       <div className="asiameet-card-text">{meeting.company}</div>
                                       <div className="asiameet-card-text">{meeting.person}</div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   ))
                ) : (
                    applications.map((application) => (
                    <div className="asiameet-card">
                        <div className="asiameet-card-time">
                            <div className="asiameet-card-time-container">
                                <img src={CalendarIcon} className="asiameet-card-time-icon" />
                                <div className="asiameet-card-time-text">{application.time}</div>
                            </div>
                        </div>
                        <div>
                            <div className="asiameet-card-container">
                                <div className="asiameet-card-text-container">
                                    <div className="asiameet-card-text">{application.place}</div>
                                    <div className="asiameet-card-text">{application.company}</div>
                                    <div className="asiameet-card-text">{application.person}</div>
                                </div>
                                <div className="asiameet-card-btn-container">
                                    <Button
                                        className="asiameet-card-btn card-btn-red"
                                        content="Cогласиться"
                                        onClick={() => addMeeting(application)}
                                    />
                                    <Button
                                        className="asiameet-card-btn card-btn-gray"
                                        content="Отклонить"
                                        onClick={() => deleteApplication(application.id)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )))}
            </div>
        </div>
    )
}
