import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import {Loader, Container, Header, Dimmer} from 'semantic-ui-react';

import {
    fetchSearch,
    receiveSearch,
    SEARCH_CONTEXTS,
    SEARCH_PAGE_COUNT
} from './actions';
import {
    getSearchFetching,
    getSearch,
    getHasMore,
    getToContext,
    getFromContext
} from './reducer';


import {useParams} from 'react-router';
import "./style.css"
import InfiniteView from "../../components/InfiniteView";
import {setWindowScrollPosition} from "../../components/WindowDimensions";
import Exponents from "../Exponents";


export function isSearchView(pathname) {
    return pathname.indexOf('search') !== -1;
}
export default function Search(props) {

    const {routeAction, location, dispatch, token, userLocation, userData, mixpanel, isPageReloaded} = props;

    const {search} = useParams();

    const loading = useSelector((state) => getSearchFetching(state.search));
    const searchItems = useSelector((state) => getSearch(state.search));
    const hasMore = useSelector((state) => getHasMore(state.search));
    const title = `Результаты поиска '${search}' в экспонентах`;

    useEffect(() => {
        if ( routeAction === 'PUSH' || isPageReloaded ) {
            window.scrollTo(0, 0);
            dispatch(receiveSearch('exponents', 1, []));
            readSearch(1);
        }
        if (mixpanel)
            mixpanel.track('Search List View',
                {...userLocation, ...userData, search, routeAction, ...location});
    }, [search]);

    useEffect(() => {
        if (isPageReloaded) {
            setWindowScrollPosition();
        }
    }, [props]);

    const loadMore = () => readSearch(Math.round(searchItems.length / SEARCH_PAGE_COUNT) + 1);

    const readSearch = (page)  => {
        dispatch(fetchSearch(
            token,
            {
                search,
                page,
                per_page: SEARCH_PAGE_COUNT,
                order: 'asc',
                orderby: 'id',
            }
        ));
    }

    if (!navigator.onLine)
        return (<Container><p>Нет подключения к Интернету</p></Container>);

    const loaderText = `Ищем '${search}' ...`;
    const headerTitle = ` Результаты поиска '${search}' в экспонентах`;

    if (loading && (searchItems.length === 0 )) {
        return (
            <Dimmer active={true} inverted className="page-loader">
                <Loader active>{loaderText}</Loader>
            </Dimmer>
        );
    }

    console.log('search items', searchItems)

    return (
        <InfiniteView
            dataLength={searchItems.length}
            nextFetch={loadMore}
            hasMore={hasMore}
            dataList={(
                 <Exponents exponents={searchItems} title={title} {...props}/>
            )}
            mustAuth={false}
            isAuth={!!token}
            isLoading={false}
            hideEndMessage={true}
            loaderText={loaderText}
            zeroText="Ничего не найдено"
        />
    );
}



