import 'whatwg-fetch';

export const PAYMENT_OPTION_ONLINE = 'tinkoff';
export const PAYMENT_OPTION_BANK = 'bacs';
export const PAYMENT_OPTION_CASH = 'cod';

export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';

export const setPaymentMethod = paymentOption => ({
  type: SET_PAYMENT_METHOD,
  paymentOption,
});

