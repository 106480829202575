import React, { Component } from 'react';
import { Card, Input } from 'semantic-ui-react';
import 'react-dadata/dist/react-dadata.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './styles.css';
import PropTypes from 'prop-types';
import { getCart } from '../../views/Cart/reducer';
import { getDiscount, getDiscountFetching } from './reducer';

class CouponCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      coupons: {},
      inputCoupon: props.inputCoupon,
    };
  }

  componentDidMount() {
  }

  setField = (e) => this.setState({inputCoupon: e.target.value});

  render() {
    return (
      <Card centered className="coupon-card">
        <Card.Header>Есть промокоды?</Card.Header>
        <Card.Content>
          <Input
            action={{
              color: 'blue',
              labelPosition: 'right',
              icon: 'hand pointer',
              content: 'Применить',
            }}
            name="inputCoupon"
            iconPosition="left"
            placeholder="введите промокод"
            fluid
            className="coupon-apply-input"
            value={this.state.inputCoupon}
            onChange={this.setField}
            loading={ this.props.isFetching }
          />
        </Card.Content>
      </Card>
    );
  }
}

CouponCard.propTypes = {
  inputCoupon: PropTypes.string,
  isFetching: PropTypes.bool,
};

CouponCard.defaultProps = {
  inputCoupon: '',
  isFetching: false,
};

const mapStateToProps = state => ({
  cart: getCart(state.cart).map(obj => (
    { productId: obj.id, variationId: obj.variationId, quantity: obj.quantity }
  )),
  isFetching: getDiscountFetching(state.discount),
  coupons: getDiscount(state.discount),
});

function mapDispatchToProps(dispatch) {
  return Object.assign({ dispatch }, bindActionCreators({}, dispatch));
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CouponCard);
