const SET_LANGUAGE = 'SET_LANGUAGE';

export const setLanguage = (language) => ({
    type: SET_LANGUAGE,
    payload: language,
});


export const getLanguage = (state) => state.language;

export default function languageReducer(state = "ru", action) {
    switch (action.type) {
        case SET_LANGUAGE:
            return action.payload;
        default:
            return state;
    }
}
