import React, {useEffect} from 'react';

import "./style.css"

import SadIcon from '../../icons/sad-icon.png';
import VkIcon from '../../icons/vk-icon.png';
import YoutubeIcon from '../../icons/yt-icon.png';
import TelegramIcon from '../../icons/telegram-icon.png';
import {useSelector} from "react-redux";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";


export default function EventsAE() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    return (
        <>
            <div className='main-block2'>
                <img src={SadIcon}/>
                <span className='text-for-sad'>{t("eventAE")}</span>
                <span className='text-for-sad'>{t("eventAELinks")}</span>
                <div className='container-icons'>
                    <a href='https://vk.com/asiaexpo_ru'>
                        <img src={VkIcon}/>
                    </a>
                    <a href='https://t.me/asia_expo'>
                        <img src={TelegramIcon}/>
                    </a>
                    <a href='https://www.youtube.com/@asiaexpo_ru'>
                        <img src={YoutubeIcon}/>
                    </a>
                </div>
            </div>
        </>
    )
}