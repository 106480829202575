import React, {useEffect} from 'react';
import {Dimmer, Loader} from "semantic-ui-react";
import {fetchOrder} from "../../views/Orders/actions";
import {toastr} from "react-redux-toastr";
import {TINKOFF_SUCCESS_PAGE} from "../../config/config";

const core = require('./core');

const payFormContainer = {
	position: 'absolute',
	left: '0',
	top: '50px',
	right: "0",
	bottom: '0',
	overflow: 'hidden',
}

const darkBackground = {
	visibility: 'hidden',
}

const divFrame = {
	height: '100%',
	width: '100%',
	maxWidth: '1200px',
	left: '50%',
	top: '0%',
	position: 'relative',
	border: '0px',
}

const payFormIframe = {
	width: '100%',
	height: '100%',
	border: 'none',
	borderRadius: '3px',
	left: '-50%',
	position: 'relative',
	backgroundColor: 'white'
};

export default function PayForm(props) {

	const { formData, dispatch, token, navigate } = props;
	const [stateLink, setStateLink] = React.useState('');

	function setupLink(target) {
		setStateLink( target);
	}

	useEffect(() => {
		core( formData, setupLink );

		const iframe = document.getElementById('pay-form-iframe');

		iframe.onload = () => {
			const parentLocation = iframe.contentWindow.parent.location;
			try {
				const iFrameLocation = iframe.contentWindow.location;
				if (iFrameLocation.href && iFrameLocation.origin === parentLocation.origin) {
					if (iFrameLocation.pathname === TINKOFF_SUCCESS_PAGE)
						dispatch(fetchOrder(token, formData.order, formData.order_key));
					formData.handleclose();
					navigate(formData.route);
				}
			}
			catch (error) {}
		};

		setTimeout(() => {
			const iframe = document.getElementById('pay-form-iframe');
			if (iframe && !iframe.contentWindow && formData.stateLoading) {
				toastr.error('Превышен интервал ожидания открытия платежной формы банка! Пожалуйста, повторите операцию позднее');
				formData.handleclose();
			}
		}, 5000);

	}, []);

	return (
		<div>
			<div id="pay-form-container" style={ payFormContainer }>
				<Dimmer active={ formData.stateLoading }>
					<Loader>Загрузка платежной формы банка, пожалуйста, подождите ...</Loader>
				</Dimmer>
				<div style={darkBackground}/>
				<div id="div_frame" style={ divFrame }>
					<iframe
						name="pay-form-iframe"
						id="pay-form-iframe"
						style={ payFormIframe }
						src={ stateLink }
					/>
				</div>
			</div>
		</div>
	);
}

