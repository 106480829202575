import React from 'react';
import './styles.css';



export default function PdfFile(props) {
    return (
        <div className='pdf-view'>
            <iframe width='100%'className='pdf-file' src='https://cms.asiaexpo.space/wp-content/uploads/2024/08/politika_personalnyh_dannyh.pdf'></iframe>
        </div>
    );
}
