import React, {useState, useEffect}from 'react';
import './style.css';
import {Form, TextArea, Button, Loader, Dimmer} from 'semantic-ui-react'
import { useSelector } from 'react-redux';

import WhatsappIcon from "../../icons/whatsapp-icon.png";
import {putTicket} from "../../components/UserLogin/actions";
import translations from "../../components/LanguageModal/translations";
import {getLanguage} from "../../components/LanguageModal/reducer";

export default function Support(props) {
    const {dispatch, token} = props;
    const userData = useSelector(state => state.userLogin.userData);
    const [isLoading, setIsLoading] = useState(false);
    const [userName, setUserName] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [messageValue, setMessageValue] = useState('');

    useEffect(() => {
        setUserName(userData.billing.first_name + ' ' + userData.billing.last_name);
        setUserPhone(userData.billing.phone);
        setUserEmail(userData.billing.email);
        window.scrollTo(0, 0);
    }, []);

    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const handleChange = (e, {value}) => {
        setMessageValue(value);
    }

    const createEmailTiсket = (to, subject, message, email, phone, who) => {
        setIsLoading(true);
        const ticket = {
            subject,
            message,
            email,
            phone,
            who,
            to,
        };
        dispatch(putTicket(token, ticket))
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });

    }
    const handleSubmit = () => {
        createEmailTiсket(
            'support@asiaexpo.space',
            'Вопрос пользователя',
            messageValue,
            userEmail,
            userPhone,
            userName
        )
        setMessageValue('');
    }

    return (
        <div className="support-view">
            <div className="support-text">{t("askQuestion")}</div>
            <a href=" https://wa.me/79094474166"><img src={WhatsappIcon} className="support-icon"/></a>
            <div className="support-text">{t("writeUsMail")}</div>
            <Form className="support-form" >
                <TextArea type="text" placeholder={t("urQuestion")} value={messageValue} onChange={handleChange} className="support-text-area" transparent/>
            </Form>
            <Button className="support-btn" onClick={handleSubmit}> {t("send")} </Button>
            <Dimmer active={isLoading} inverted>
                <Loader active={isLoading}>{t("sendingMessage")}...</Loader>
            </Dimmer>
        </div>

    )

}
