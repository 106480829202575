import { DADATA_API_KEY } from '../../config/secrets';

export const REQUEST_LOCATION_DATA = 'REQUEST_LOCATION_DATA';
export const SET_LOCATION_DATA = 'SET_LOCATION_DATA';


export const requestLocationPermission = () => ({
  type: REQUEST_LOCATION_DATA,
});

export const setLocationData = (data) => ({
  type: SET_LOCATION_DATA,
  data,
});

export const requestCoordinates = () => (dispatch) => {
  function success(pos) {
    const crd = pos.coords;
    const location = {
      latitude: crd.latitude,
      longitude: crd.longitude,
      accuracy: crd.accuracy,
      timestamp: String(new Date()),
      condition: 'granted',
    };
    dispatch(fetchLocationDaData(location));
    //dispatch(fetchLocationNominatim([crd.latitude, crd.longitude]));
  }
  function errors(err) {
    const location = {
      error: `ERROR(${err.code}): ${err.message}`,
      timestamp: String(new Date()),
      condition: 'error',
    };
    dispatch(setLocationData(location));
  }
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  dispatch(requestLocationPermission());

  if (navigator.geolocation) {
    navigator.permissions
      .query({ name: 'geolocation' })
      .then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          // If granted then you can directly call your function here
          navigator.geolocation.getCurrentPosition(success, errors, options);
        } else if (result.state === 'denied') {
          // If denied then you have to show instructions to enable location
          const location = { condition: 'denied', error: 'Геолокация не разрешена пользователем' };
          dispatch(setLocationData(location));
        }
      });
  } else {
    const location = { condition: 'unavailable', error: 'Геолокация не поддерживается браузером' };
    dispatch(setLocationData(location));
  }
};

export const fetchLocationNominatim = (location = {}) => (dispatch) => {

  const headers = { 'User-Agent': 'feedtech.su' };
  const url =`https://nominatim.openstreetmap.org/reverse?lat=${location.latitude}&lon=${location.longitude}&format=json`;

    fetch(url, { headers })
      .then((response) => response.json())
      .then((json) => {
        if (json.address) {
          dispatch(setLocationData({...location, address: json.address}));
        } else {
          dispatch(setLocationData({...location, address_error: 'Ошибка запроса: сервис не доступен'}));
        }
        console.error(json);
      });
};

export const fetchLocationDaData = (location= {}) => (dispatch) => {

  const headers = { Authorization: 'Token ' + DADATA_API_KEY };
  const url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address?lat=${location.latitude}&lon=${location.longitude}&count=1&radius_meters=1000&language=ru`;

  return fetch(url, { headers })
    .then((response) => response.json())
    .then((json) => {
      if (json.suggestions) {
        const extLocation={...location, address: json.suggestions[0].value};
        dispatch(setLocationData(extLocation));
      } else {
        dispatch(setLocationData({...location, address_error: 'Ошибка запроса: ' + json.message()}));
      }
    })
    .catch( (ex) => setLocationData({...location, address_error: 'Ошибка запроса: ' + String(ex)}) );
};


