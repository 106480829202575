export const SET_FORM_CHANGED = 'SET_FORM_CHANGED';
export const RESET_FORM_CHANGED = 'RESET_FORM_CHANGED';
export const SET_POPUP_OPEN = 'SET_POPUP_OPEN';
export const SET_POPUP_CLOSE = 'SET_POPUP_CLOSE';


export const setFormChanged = () => ({
    type: SET_FORM_CHANGED,
});

export const resetFormChanged = () => ({
    type: RESET_FORM_CHANGED,
});

export const setPopupOpen = (route) => ({
    type: SET_POPUP_OPEN,
    route,
});

export const setPopupClose = () => ({
    type: SET_POPUP_CLOSE,
})
