import React, { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import './style.css';

import { Button, Modal, Form, FormField, Input } from 'semantic-ui-react'

import { ReactComponent as KrestIcon } from "../../icons/krest-icon.svg";
import { ReactComponent as PhoneIcon } from "../../icons/phone-solid-icon.svg";
import { ReactComponent as EmailIcon } from "../../icons/envelope-solid-icon.svg";

export default function Exponent(props) {
    const [cards, setCards] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const surnameRef = useRef();
    const nameRef = useRef();
    const positionRef = useRef();
    const phoneRef = useRef();
    const emailRef = useRef();

    const addCard = (newCard) => {
        setCards([...cards, newCard])
    }

    const deleteCard = (id) => {
        setCards(cards.filter((card) => card.id !== id))
    }

    return (
        <div className="exponent-view">
            <div className="exponent-info">
                {cards.map((card) => (
                    <div className="exponent-card">
                        <div className="exponent-text-container">
                            <div className="exponent-card-name">{card.name + ' ' + card.surname}</div>
                            <KrestIcon className="exponent-icon-delete" onClick={() => deleteCard(card.id)}/>
                        </div>
                        <div className="exponent-card-container">
                            <div className="exponent-card-position">{card.position}</div>
                            <div className="exponent-contacts">
                                <div className="exponent-contacts-container">
                                    <PhoneIcon classname="exponent-contacts-icon"/>
                                    <div className="exponent-contact-text">{card.phone}</div>
                                </div>
                                <div className="exponent-contacts-container">
                                    <EmailIcon classname="exponent-contacts-icon"/>
                                    <div className="exponent-contact-text">{card.email}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Modal
                className="exponent-popup"
                open={isOpen}
                trigger={
                    <Button
                        onClick={() => setIsOpen(true)}
                        className="exponent-btn"
                        content="Добавить"
                    />}
                onClose={() => setIsOpen(false)}
                centered
            >
                <Modal.Content className="exponent-popup-content">
                    <KrestIcon className="exponent-popup-close" onClick={() =>setIsOpen(false)}/>
                    <Form className="exponent-popup-form">
                        <FormField className="exponent-popup-field">
                            <label className="exponent-popup-label">Фамилия</label>
                            <Input className="exponent-popup-input" placeholder="Иванов" ref={surnameRef}></Input>
                        </FormField>
                        <FormField className="exponent-popup-field">
                            <label className="exponent-popup-label">Имя</label>
                            <Input className="exponent-popup-input" placeholder="Иван" ref={nameRef}></Input>
                        </FormField>
                        <FormField className="exponent-popup-field">
                            <div className="exponent-popup-label">Должность</div>
                            <Input className="exponent-popup-input" placeholder="Руководитель маркетинга" ref={positionRef}></Input>
                        </FormField>
                        <FormField className="exponent-popup-field">
                            <label className="exponent-popup-label">Телефон</label>
                            <Input className="exponent-popup-input" placeholder="+7 (999) 999-99-99" ref={phoneRef}></Input>
                        </FormField>
                        <FormField className="exponent-popup-field">
                            <label className="exponent-popup-label">E-mail</label>
                            <Input className="exponent-popup-input" placeholder="asiaexpo@example.ru"ref={emailRef}></Input>
                        </FormField>
                        <Button
                            className="exponent-popup-btn"
                            content="Добавить"
                            onClick={() => {
                                addCard({
                                    surname: surnameRef.current.value,
                                    name: nameRef.current.value,
                                    position: positionRef.current.value,
                                    phone: phoneRef.current.value,
                                    email: emailRef.current.value,
                                    id: uuidv4()
                                });
                                setIsOpen(false);
                            }}
                        />
                    </Form>
                </Modal.Content>
            </Modal>
        </div>

    )
}
