import React, {useState, useRef, useEffect} from 'react';
import './style.css';


import { Button, Form, FormField, Dropdown, Search } from 'semantic-ui-react'
import { ReactComponent as ArrowIcon } from "../../icons/arrow-dropdown-icon.svg";
import {agrSegment, companySettings, personSettings, timeSettings, placeSettings} from './constants.js';
import {EXPONENTS_PAGE_COUNT, fetchExponents, fetchSegments} from "../Exponents/action";
import {useSelector} from "react-redux";
import {getExponents, getSegments} from "../Exponents/reducer";




const initialState = { isLoading: false, results: [], value: '' }

export default function FormMeeting(props) {
    const { dispatch,  exponents: incomeExponents } = props;
    const [state, setState] = useState(initialState);
    const exponents = incomeExponents ?? useSelector((state) => getExponents(state.exponents));
    const segments = useSelector((state) => getSegments(state.exponents));
    const [segmentArray, setSegmentArray] = useState([]);
    const [exponentsArray, setExponentsArray] = useState([]);
    const [personArray, setPersonArray] = useState([]);
    const [formData, setFormData] = useState({});
    const [isFilterActive, setIsFilterActive] = useState({
        segment: false,
        exponents: false,
        person: false,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        readExponents(3);

        setSegmentArray([ ...segments.map((item) => ({ text: item.name, value: item.name }))]);

        setExponentsArray([ ...exponents.map((item) => ({ text: item.name, value: item.name }))]);

        setPersonArray([ ...exponents.map((item) => (item.billing.company_chief_name !== '' ? { text: item.billing.company_chief_name, value: item.billing.company_chief_name } : {}))]);
    }, []);

    console.log(exponentsArray);

    const readExponents = (page)  => {
        const params = {
            page,
            per_page: EXPONENTS_PAGE_COUNT,
            order: 'asc',
            orderby: 'upper(name)',
            event: 'ASIAEXPO 2024',
        };

        dispatch(fetchExponents(params));

        if (page === 1) dispatch(fetchSegments({
            hide_empty: true,
            order: 'asc',
            orderby: 'name',
        }));
    }

    const handleFilter = (type,key) => {
        switch (type) {
            case 'person':
                if (key === 'Не выбрано') {
                    setSegmentArray([

                        ...exponents.filter((item) => item.name === formData.company)
                            .flatMap((item) => item.segments.map((segment) => ({text: segment.name, value: segment.name})))

                    ])
                    setExponentsArray([

                        ...exponents.filter((item) => item.segments.some((segment)=> segment.name === formData.segment))
                            .map((item) =>
                                ({
                                    text: item.name,
                                    value: item.name
                                })
                            )
                    ])
                    console.log('dsdsadasd')

                } else {
                    setExponentsArray([

                        ...exponents.filter((item) => item.billing.company_chief_name === key)
                            .map((item) =>
                                ({
                                    text: item.name,
                                    value: item.name
                                })
                            )
                    ]);
                    setIsFilterActive({
                        ...isFilterActive,
                        exponents: true
                    })
                    setSegmentArray([

                        ...exponents.filter((item) => item.billing.company_chief_name === key)
                            .flatMap((item) => item.segments
                                .map((segment)=>
                                    ({
                                        text: segment.name,
                                        value: segment.name
                                    })
                                )
                            )
                    ]);
                }

                break;

            case 'company':
                if (key === 'Не выбрано') {
                    setPersonArray([

                        ...exponents.filter((item) => item.segments.some((segment)=> segment.name === formData.segment))
                            .map((item) =>
                                ({
                                    text: item.billing.company_chief_name,
                                    value: item.billing.company_chief_name
                                })
                            )
                    ]);

                    setSegmentArray([

                        ...exponents.filter((item) => item.billing.company_chief_name === formData.person)
                            .flatMap((item) => item.segments
                                .map((segment)=>
                                    ({
                                        text: segment.name,
                                        value: segment.name
                                    })
                                )
                            )
                    ]);

                } else {
                    setPersonArray([

                        ...exponents.filter((item) => item.name === key)
                            .map((item) =>
                                ({
                                    text: item.billing.company_chief_name,
                                    value: item.billing.company_chief_name
                                })
                            )
                    ]);
                   setIsFilterActive({
                       ...isFilterActive,
                       person: true
                   });
                    setSegmentArray([

                        ...exponents.filter((item) => item.name === key)
                            .flatMap((item) => item.segments.map((segment)=>
                                ({
                                    text: segment.name,
                                    value: segment.name
                                })
                            ))
                    ])

                }

                break;

            case 'segment':
                if (key==='Не выбрано') {
                    setExponentsArray([

                        ...exponents.filter((item) => item.billing.company_chief_name === formData.person)
                            .map((item) =>
                                ({
                                    text: item.name,
                                    value: item.name
                                })
                            )
                    ]);
                }

                !isFilterActive.exponents && setExponentsArray([

                    ...exponents.filter((item) => item.segments.some((segment)=> segment.name === key))
                        .map((item) =>
                            ({
                                text: item.name,
                                value: item.name
                            })
                        )
                ])
                !isFilterActive.person && setPersonArray([

                    ...exponents.filter((item) => item.segments.some((segment)=> segment.name === key))
                        .map((item) =>
                            ({
                                text: item.billing.company_chief_name,
                                value: item.billing.company_chief_name
                            })
                        )
                ]);

                break;
        }


    }

    const handleChangePerson = (e, {value}) => {
        setFormData({
            ...formData,
            person: value,
        });
        console.log(segmentArray, 'segmentArray');
        handleFilter('person', value);
        console.log(formData, 'formData');

    }

    const handleChangeCompany = (e, {value}) => {
        setFormData({
            ...formData,
            company: value,
        });
        console.log(segmentArray, 'segmentArray');
        handleFilter('company', value) ;
    }

    const handleChangeSegment = (e, {value}) => {
        setFormData({
            ...formData,
            segment: value,
        });
        handleFilter('segment', value);
    }

    const handleSearchChange = (e, { value }) => {
        setState({ ...state, isLoading: true, value });


        const re = new RegExp(_.escapeRegExp(value), 'i');
        const isMatch = (result) => re.test(result.name) || re.test(result.billing.company_chief_name);

        const filteredResults = _.filter(exponents, isMatch);
        console.log(filteredResults, 'filteredResults');

        const updatedResults = filteredResults.map(result => ({
            name: result.name,
            person: result.billing.company_chief_name,
            segment: result.segments[0].name,
            title: `${result.name + ' ' + result.billing.company_chief_name}` // Здесь можно задать формат отображения данных
        }));

        console.log(updatedResults, 'updatedResults');

        setState({
            ...state,
            isLoading: false,
            results: updatedResults,
            value: value,
        });
    };

    const handleResultSelect = (e, { result }) => {
        setState({ ...state, value: result.name + ' ' + result.person});
        handleFilter('segment', result.segment);
        handleFilter('person', result.person);
        handleFilter('company', result.name);
        setFormData(
            {...formData, company: result.name, person: result.person, segment: result.segment}
        );



    };

    return (
        <div className="meeting-view">
            <Search
                className="meeting-search"
                icon="meeting-icon"
                placeholder="Поиск"
                onResultSelect={handleResultSelect}
                onSearchChange={_.debounce(handleSearchChange, 500, {
                        leading: true,
                    })}
                fluid
                results={state.results}
                value={state.value}
            />
            <Form className="meeting-form">
                <FormField className="meeting-formfield">
                    <label className="meeting-label">Отрасль СХ</label>
                    <Dropdown
                        className="meeting-dropdown"
                        fluid
                        search
                        selection
                        options={segmentArray}
                        placeholder='Отрасль'
                        icon={<ArrowIcon />}
                        name='segment'
                        value={formData.segment}
                        onChange={handleChangeSegment}

                    />
                </FormField>
                <FormField className="meeting-formfield">
                    <label className="meeting-label">Выберите компанию</label>
                    <Dropdown
                        className="meeting-dropdown"
                        fluid
                        search
                        selection
                        options={exponentsArray}
                        placeholder='Компания'
                        icon={<ArrowIcon />}
                        name='company'
                        value={formData.company}
                        onChange={handleChangeCompany}
                    />
                </FormField>
                <FormField className="meeting-formfield">
                    <label className="meeting-label">Выберите человека</label>
                    <Dropdown
                        className="meeting-dropdown"
                        fluid
                        search
                        selection
                        options={personArray}
                        placeholder='ФИО'
                        icon={<ArrowIcon />}
                        name='person'
                        value={formData.person}
                        onChange={handleChangePerson}
                    />
                </FormField>
                <FormField className="meeting-formfield">
                    <label className="meeting-label">Выберите время встречи</label>
                    <input className="meeting-time" type="time" />
                </FormField>
                <FormField className="meeting-formfield">
                    <label className="meeting-label">Выберите место встречи</label>
                    <Dropdown
                        className="meeting-dropdown"
                        fluid
                        search
                        selection
                        options={placeSettings}
                        placeholder="Площадка"
                        icon={<ArrowIcon />}
                    />
                </FormField>

                <div className="meeting-clear">Очистить форму</div>
                <Button
                    className="meeting-btn"
                    type="submit"
                    content="Назначить"
                />
            </Form>
        </div>
    );
}

