export const SEARCH_OPEN = 'SEARCH_OPEN';
export const SEARCH_CLOSE = 'SEARCH_CLOSE';
export const AUTH_OPEN = 'AUTH_OPEN';
export const AUTH_CLOSE = 'AUTH_CLOSE';

export const openSearch = () => ({
  type: SEARCH_OPEN,
});

export const closeSearch = () => ({
  type: SEARCH_CLOSE,
});

export const openAuth = () => ({
  type: AUTH_OPEN,
});

export const closeAuth = () => ({
  type: AUTH_CLOSE,
});

