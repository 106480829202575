import 'whatwg-fetch';
import config, {AUTH_INVALID_MESSAGE, AUTH_INVALID_TOKEN, SERVICE_LOGICAL_ACCESS_DENIED} from '../../config/config';
import {dropToken} from "../../components/UserLogin/actions";
import {toastr} from "react-redux-toastr";
import _ from "lodash";
import {removeAllProductsFromCart} from "../Cart/actions";

export const REQUEST_ORDERS = 'REQUEST_ORDERS';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const RECEIVE_1PAGE_ORDERS = 'RECEIVE_1PAGE_ORDERS';
export const ORDERS_PAGE_COUNT = 20;
export const CHECKOUT_INSERT_ORDER = 'CHECKOUT_INSERT_ORDER';
export const CHECKOUT_ORDER_PLACED = 'CHECKOUT_ORDER_PLACED';
export const REQUEST_ORDER_UPDATE = 'REQUEST_ORDER_UPDATE';
export const RECEIVE_ORDER_DATA = 'RECEIVE_ORDER_DATA';

export const checkoutInsertOrder = orderData => ({
  type: CHECKOUT_INSERT_ORDER,
  orderData,
});

export const checkoutOrderPlaced = order => ({
  type: CHECKOUT_ORDER_PLACED,
  order,
});

export const requestOrderUpdate = order_id => ({
  type: REQUEST_ORDER_UPDATE,
  order_id,
});

export const receiveOrderData = order => ({
  type: RECEIVE_ORDER_DATA,
  order,
});

export const requestOrders = (page) => ({
  type: REQUEST_ORDERS,
    page
});

export const receiveOrders = (orders) => ({
  type: RECEIVE_ORDERS,
  orders,
});

export const receive1PageOrders = (orders) => ({
  type: RECEIVE_1PAGE_ORDERS,
  orders,
});

const process_api_error = (json, dispatch) => {
    dispatch(checkoutOrderPlaced({}));
    if (json.code === AUTH_INVALID_TOKEN) {
        dispatch(dropToken());
        toastr.error(AUTH_INVALID_MESSAGE);
    } else
        if (json.code === SERVICE_LOGICAL_ACCESS_DENIED)
            toastr.error(json.message);
        else
            toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const fetchOrder = (token, order_id, order_key) => (dispatch) => {
    const query = JSON.stringify({nothing: ''});
    dispatch(updateOrder(token, order_id, query, order_key, true));
};

export const updateOrder = (token, order_id, query, order_key, silent = false) => (dispatch) => {
  dispatch(requestOrderUpdate(order_id));

  const headers = token ? { Authorization: 'Bearer ' + token } : {};
  let url = config.API_UPDATE_ORDER_URL + order_id + (order_key ? `?order_key=${order_key}` : '');

  const formData = new FormData();
  formData.append('query', query);

  return fetch(url, { method: 'post', headers, body: formData, })
      .then((response) => response.json())
      .then((json) => {
        if (json.code === 200) {
          dispatch(receiveOrderData(json.data));
          dispatch(checkoutOrderPlaced(json.data));
          if (!silent)
              toastr.success(`Заказ №${json.data.id} изменен`);
        } else {
          process_api_error(json, dispatch);
        }
      })
      .catch( (ex) => {
        toastr.error('Ошибка запроса: ' + String(ex))
      });
};

export const putOrder = (token, orderData, funcNavigate, toNavigate) => (dispatch) => {
  dispatch(checkoutInsertOrder(orderData));

  const headers = token ? { Authorization: 'Bearer ' + token } : {};
  let url = config.API_CREATE_ORDER_URL;

  const formData = new FormData();
  _.each( orderData, (value, key) => formData.append(String(key), value) );

  return fetch(url, { method: 'post', headers, body: formData, })
      .then((response) => response.json())
      .then((json) => {
          if (json.code === 200) {
            dispatch(checkoutOrderPlaced(json.data));
            dispatch(removeAllProductsFromCart());
            toastr.success(`Заказ №${json.data.id} успешно создан!`);
            funcNavigate(toNavigate + "/" + json.data.order_key);
          } else {
              console.warn(' error while put order', url, orderData,json);
              process_api_error(json, dispatch);
          }
      })
      .catch( (ex) => {
          dispatch(checkoutOrderPlaced({}));
          toastr.error('Ошибка запроса: ' + String(ex));
      });
};

export const fetchOrders = (token, params = {}) => (dispatch) => {

  dispatch(requestOrders(params.page));

  const headers = token ? { Authorization: 'Bearer ' + token } : {};
  let url;
  if (params) {
      const url_query = '?'
      + Object.keys(params)
          .map( (k) => (k === 'id' ? '' : k + '=' + encodeURIComponent(params[k])) )
          .join('&');
      url = (params.id ? config.API_ORDER_URL + String(params.id) : config.API_ORDERS_URL) + url_query;
  } else
      url = config.API_ORDERS_URL;

  return fetch(url, {headers})
    .then((response) => response.json())
    .then((json) => {
        if (json.code === 200) {
          dispatch(params.page === 1 ? receive1PageOrders(json.data) : receiveOrders(json.data))
        } else {
          process_api_error(json, dispatch);
        }
    })
    .catch((ex) => {
      toastr.error('Ошибка запроса: ' + String(ex));
    });
};
