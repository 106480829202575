import { combineReducers } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {ADD_PRODUCT, SET_QUANTITY, REMOVE_PRODUCT, REMOVE_ALL_PRODUCTS} from './actions';

export const cartProductPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  variationId: PropTypes.number,
  selections: PropTypes.object,
  dateAdded: PropTypes.string.isRequired,
});

const items = (state = [], action) => {
  switch (action.type) {
    case ADD_PRODUCT: {
      let product = null;
      if (!_.isNil(action.variationId)) {
        product = _.find(state, { id: action.id, variationId: Number(action.variationId) });
      } else {
        product = _.find(state, { id: action.id });
      }

      let newProduct = null;
      if (!_.isNil(product)) {
        newProduct = { ...product };
        newProduct.quantity += 1;
      } else {
        const now = new Date();
        newProduct = {
          id: action.id,
          price: action.price,
          name: action.name,
          image: action.image,
          quantity: 1,
          dateAdded: now.toString(),
          virtual: action.virtual,
        };

        if (!_.isNil(action.variationId)) {
          newProduct.variationId = Number(action.variationId);
          newProduct.selections = action.selections;
        }
      }

      return _.unionBy(state, [newProduct], !_.isNil(action.variationId) ? 'variationId' : 'id');
    }
    case REMOVE_PRODUCT:
      if (!_.isNil(action.variationId)) {
        return state.filter((item) => item.variationId !== Number(action.variationId));
      }
      return state.filter((item) => item.id !== action.id);

    case REMOVE_ALL_PRODUCTS:
      return [];

    case SET_QUANTITY: {
      let product = null;
      if (!_.isNil(action.variationId)) {
        product = _.find(state, { id: action.id, variationId: Number(action.variationId) });
      } else {
        product = _.find(state, { id: action.id });
      }

      if (!_.isNil(product)) {
        const newProduct = { ...product, quantity: action.quantity };

        // Overwrite product with new details
        const cartProducts = _.unionBy([newProduct], state, !_.isNil(action.variationId) ? 'variationId' : 'id');

        // Order cart products by their added date
        return _.orderBy(cartProducts, ['dateAdded'], ['asc']);
      }

      return state;
    }
    default:
      return state;
  }
};

export const getCart = (state) => state.items;

export const getCartItems = (state) =>
      state.items.map(item => ({ id: item.id, quantity: item.quantity, variationId: _.isNil(item.variationId) ? '' : item.variationId }),
  );

export const getTotalPrice = (state) => _.sumBy(state.items, (item) => (Number(item.quantity) * Number(item.price)));

export const hasOnlyVirtuals = (state = {}) => {
  let only_virtuals = true;
  if (state.items)
    state.items.forEach(item => { only_virtuals &= item.virtual; });
  return only_virtuals;
}

export default combineReducers({
  items,
});
